import React, { useState ,useEffect} from "react";
import { BottomSheet } from "react-spring-bottom-sheet";
import LoadingOverlay from "react-loading-overlay";
import { makeStyles } from "@material-ui/core/styles";
import { useGoogleLogin } from "@react-oauth/google";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { checkGoogleIntegration } from "../constants/ApiCall";
import axios from 'axios';
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import moment from "moment";

import Header from "../Components/Integration Components/Header";
import Integration from "../Components/Integration Components/Integration";
import Button from "../Components/Integration Components/Button";
import Logo from "../images/gmail.png";

const GOOGLE_CLIENT_ID = "322938904388-p7l4fso2n9g93n8irg3c9get69rn3vk4.apps.googleusercontent.com";
const REDIRECT_URI = "https://integration.3sigmacrm.com/integration/gmail/callback";

function Gmail(props) {
  const [open, setOpen] = useState(true);
  const [integration, setIntegration] = useState(false);
  const [loading, setLoading] = useState(true); // Start with loading true
  const [loadingText, setLoadingText] = useState("Checking integration status...");
  const [integrationDate] = useState(new Date());

  const isIframe = window !== window.parent;


  const getTokenFromUrl = () => {
    const url = new URL(window.location.href);
    return url.searchParams.get("token");
  };

  useEffect(() => {
    const checkIntegration = async () => {
      try {
        const token = getTokenFromUrl();
        if (!token) {
          throw new Error("Authorization token not found");
        }
        
        const integrationStatus = await checkGoogleIntegration(token);
        
        if (integrationStatus.isIntegrated && integrationStatus.scopes.gmail) {
          setIntegration(true);
        }
      } catch (error) {
        console.error("Error checking Gmail integration:", error);
      } finally {
        setLoading(false);
      }
    };
    
    checkIntegration();
  }, []);

  const googleLogin = useGoogleLogin({
    flow: "auth-code",
    scope: "https://mail.google.com/",
    redirect_uri: REDIRECT_URI,
    ux_mode: isIframe ? "redirect" : "popup",
    onSuccess: async (codeResponse) => {
      try {
        setLoading(true);
        setLoadingText("Connecting to Gmail...");
        
        const token = getTokenFromUrl();
        if (!token) {
          throw new Error("Authorization token not found");
        }

        const response = await axios.post('https://mapi2.3sigmacrm.com/api/v1/user/get-google-tokens', 
          { code: codeResponse.code },
          { 
            headers: { 
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          }
        );
        
        if (response.data.status) {
          setIntegration(true);
          NotificationManager.success("Gmail connected successfully!");
          
          if (isIframe) {
            window.parent.postMessage({ type: 'INTEGRATION_SUCCESS' }, '*');
            setTimeout(() => {
              window.parent.postMessage({ type: 'INTEGRATION_COMPLETE' }, '*');
            }, 1500);
          } else {
            props.history.push('/integration/success');
          }
        } else {
          throw new Error(response.data.message || "Connection failed");
        }
        
      } catch (error) {
        console.error("Gmail integration error:", error);
        NotificationManager.error(error.response?.data?.message || "Connection failed");
        if (isIframe) {
          window.parent.postMessage({ 
            type: 'INTEGRATION_ERROR',
            error: error.message 
          }, '*');
        }
      } finally {
        setLoading(false);
      }
    },
    onError: (error) => {
      console.error("Google login error:", error);
      NotificationManager.error("Authentication failed");
      setLoading(false);
      if (isIframe) {
        window.parent.postMessage({ 
          type: 'INTEGRATION_ERROR',
          error: 'Authentication failed' 
        }, '*');
      }
    }
  });

  const handleConnect = () => {
    const token = getTokenFromUrl();
    if (!token) {
      NotificationManager.error("Authorization token not found");
      return;
    }
    googleLogin();
  };

  const useStyles = makeStyles(() => ({
    container: {
      padding: "0 13px 0 17px",
      "& .integration_type": {
        gap: "12px",
      },
      "& button": {
        width: "330px",
        position: "relative",
        marginBottom: "50px !important",
        marginTop: "70px !important",
        display: "flex",
        alignItems: "center",
        paddingLeft: "33px",
        gap: "9px"
      },
    },
    text: {
      fontWeight: "600",
      fontSize: "15px",
      lineHeight: "18px",
      color: "#000000",
      margin: "0",
    }
  }));

  const classes = useStyles();

  return (
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <BottomSheet
        open={open}
        snapPoints={({ minHeight, maxHeight }) => [minHeight, maxHeight / 1]}
        defaultSnap={({ lastSnap, snapPoints }) =>
          lastSnap ?? Math.max(...snapPoints)
        }
      >
        <LoadingOverlay active={loading} spinner text={loadingText}>
          <Header setOpen={setOpen} />
          <div className={`container integration_container ${classes.container}`}>
            <Integration
              title="Gmail"
              logo={<img src={Logo} alt="Gmail" width="40" height="40" />}
            />
            <div className={classes.text}>
              Now autosync your new and existing Google mail emails to 3sigma CRM
              easily, you will be able to bulk send emails directly and create
              activities easily.
            </div>
            
            {integration ? (
              <div>
                <div className={`${classes.text} mt-4 mb-4 pb-1`}>
                  <p style={{ marginLeft: "10px" }}>
                    The Gmail integration enables you to run bulk email campaigns directly 
                    from your Gmail account. It also allows you to automate email sending 
                    to your leads as part of your workflow automation.
                  </p>
                </div>
                <div className={classes.text}>
                  Gmail connected successfully on{" "}
                  {moment(integrationDate).format("DD MMM YYYY hh:mm A")}
                </div>
              </div>
            ) : (
              <Button
                onClick={handleConnect}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Connect
              </Button>
            )}
          </div>
        </LoadingOverlay>
        <NotificationContainer />
      </BottomSheet>
    </GoogleOAuthProvider>
  );
}

export default Gmail;