import React from "react";
import PropTypes from 'prop-types';
import { Phone, Mail, Globe, Calendar } from 'lucide-react';
import './QuotationView.css';

const QuotationView = ({ quotationData, currencySymbol, isSmallScreen, utils }) => {
  const {
    getCompleteImageUrl,
    createDate,
    formatWithCommas,
    decimalToEnglish,
  } = utils;

  return (
    <div className="quotation-container">
    <div className="quotation-card">
      {/* Modified Header Section */}
      <div className="quotation-header">
  {/* Left Side - Company Info */}
  <div className="company-info">
  <div className="company-logo">
    <img
      src={getCompleteImageUrl(quotationData.organization.iconUrl)}
      alt="Company Logo"
    />
  </div>
     
      

      
  <div className="company-details">
  <h2 className="company-name">{quotationData?.organization?.name}</h2>
  {quotationData?.organization?.address && (
    <p className="company-address">
      {quotationData.organization.address}
    </p>
  )} 
            {quotationData?.organization?.phone && (
      <p className="company-contact">
        <Phone size={14} className="icon" />
        {quotationData.organization.phone}
      </p>
    )}
              
              {quotationData?.organization?.website && (
      <a href={`http://${quotationData.organization.website}`} 
         target="_blank" 
         rel="noopener noreferrer" 
         className="company-website">
        <Globe size={14} className="icon" />
        {quotationData.organization.website}
      </a>
    )}
  </div>
</div>

  <div className="quotation-details-wrapper">
    <div className="quotation-details">
      <h1 className="quotation-title">QUOTATION</h1>
      <div className="quotation-info">
        <div className="info-row">
          <span className="label">Quotation No:</span>
          <span className="value">{quotationData?.quotationId}</span>
        </div>
        <div className="info-row">
          <span className="label">Date:</span>
          <span className="value">{createDate(quotationData?.createdAt)}</span>
        </div>
        <div className="info-row">
          <span className="label">Prepared By:</span>
          <span className="value">{quotationData?.createdBy}</span>
        </div>
      </div>
    </div>
  </div>
</div>

        <div className="section-divider"></div>

        {/* Client Section */}
        <div className="client-section">
  <h3 className="section-title">Quotation For:</h3>
  <div className="client-details">
    {/* Render all lead data that exists and isn't empty */}
    {Object.entries(quotationData?.lead || {}).map(([key, value]) => {
      // Skip if value is null, undefined, or empty string
      if (!value || value === '') return null;
      
      // Skip internal/technical fields if any
      if (key === '_id' || key === 'createdAt' || key === 'updatedAt') return null;
       // Handle address object specially
       if (key === 'addres' && typeof value === 'object') {
        return (
          <div key={key} className="client-info">
            <span className="label">Address:</span>
            <span className="value">{value.address}</span>
          </div>
        );
      }


      return (
        <div key={key} className="client-info">
          {/* Special handling for known fields like phone and email */}
          {key === 'phone' ? (
            <>
              <Phone size={16} className="icon" />
              <span className="value">{value}</span>
            </>
          ) : key === 'email' ? (
            <>
              <Mail size={16} className="icon" />
              <span className="value">{value}</span>
            </>
          ) : (
            <>
              {/* For all other fields, convert key to label */}
              <span className="label">
                {key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, ' ')}:
              </span>
              <span className="value">{value}</span>
            </>
          )}
        </div>
      );
    })}
  </div>
</div>

        <div className="section-divider"></div>

        {/* Products Table */}
        <div className="table-container">
          <table className="products-table">
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Description</th>
                <th className="text-right">Unit Price</th>
                <th className="text-right">Qty</th>
                <th className="text-right">Amount</th>
              </tr>
            </thead>
            <tbody>
              {quotationData?.products?.map((product, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td className="product-name">{product.name}</td>
                  <td>{product.description}</td>
                  <td className="text-right">
                    {currencySymbol} {formatWithCommas(product.unitPrice)}
                  </td>
                  <td className="text-right">{product.quantity}</td>
                  <td className="text-right">
                    {currencySymbol} {formatWithCommas(product.unitPrice * product.quantity)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="section-divider"></div>

        {/* Summary & Amount in Words Section */}
        <div className="summary-wrapper">
          {/* Amount in Words */}
          <div className="amount-words">
            <h4 className="section-title">Amount in Words</h4>
            <p className="words">
              {currencySymbol}: {decimalToEnglish(quotationData.total)}
            </p>
            
            {/* Notes Section */}
            {quotationData?.notes && (
              <div className="notes-section">
                <h4 className="section-title">Notes</h4>
                <p className="notes-text">{quotationData.notes}</p>
              </div>
            )}
          </div>

          {/* Calculations */}
          <div className="summary-section">
            <div className="calculations">
              {quotationData.receivedAmount && (
                <>
                  <div className="calc-row">
                    <span className="label">Payment Mode</span>
                    <span className="value capitalize">
                      {quotationData.receivedPaymentMode}
                    </span>
                  </div>
                  <div className="calc-row">
                    <span className="label">Received Amount</span>
                    <span className="value">
                      {currencySymbol} {formatWithCommas(quotationData.receivedAmount)}
                    </span>
                  </div>
                </>
              )}

              <div className="calc-row">
                <span className="label">Subtotal</span>
                <span className="value">
                  {currencySymbol} {formatWithCommas(quotationData.subTotal)}
                </span>
              </div>

              {quotationData?.discount && (
                <div className="calc-row">
                  <span className="label">
                    Discount ({quotationData.discount.type === "fixed" ? "fixed" : `${quotationData.discount.rate}%`})
                  </span>
                  <span className="value">
                    {currencySymbol} {formatWithCommas(quotationData.discount.amount)}
                  </span>
                </div>
              )}

              {quotationData?.additionalCharges?.map((charge, index) => (
                <div key={index} className="calc-row">
                  <span className="label">
                    {charge.title} ({charge.type === "fixed" ? "fixed" : `${charge.rate}%`})
                  </span>
                  <span className="value">
                    {currencySymbol} {formatWithCommas(charge.amount)}
                  </span>
                </div>
              ))}

              {quotationData?.taxes?.map((tax, index) => (
                <div key={index} className="calc-row">
                  <span className="label">
                    {tax.title} ({tax.rate}%)
                  </span>
                  <span className="value">
                    {currencySymbol} {formatWithCommas(tax.amount)}
                  </span>
                </div>
              ))}

              <div className="total-row">
                <span className="label">TOTAL</span>
                <span className="value">
                  {currencySymbol} {formatWithCommas(quotationData.total)}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="section-divider"></div>

        {/* Bank Details */}
        <div className="quotation-footer">
  {/* Bank Details Card */}
  <div className="footer-card bank-details">
    <h4 className="section-title">Bank Details</h4>
    <div className="bank-info">
      <div className="bank-row">
        <span className="bank-label">Bank Name</span>
        <span className="bank-value">{quotationData?.bankDetails?.bankName}</span>
      </div>
      <div className="bank-row">
        <span className="bank-label">Account Number</span>
        <span className="bank-value">{quotationData?.bankDetails?.accountNumber}</span>
      </div>
      <div className="bank-row">
        <span className="bank-label">IFSC Code</span>
        <span className="bank-value">{quotationData?.bankDetails?.ifscCode}</span>
      </div>
      <div className="bank-row">
        <span className="bank-label">Account Holder</span>
        <span className="bank-value">{quotationData?.bankDetails?.accountHolderName}</span>
      </div>
    </div>
  </div>
          

          {/* New Terms and Conditions Section */}
          {quotationData?.termsAndCondition && (
           <div className="footer-card terms-conditions">
           <h4 className="section-title">Terms and Conditions</h4>
           <div className="terms-content">
             {quotationData?.termsAndCondition}
           </div>
         </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default QuotationView;