import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  :root {
    --bs-body-font-family: 'Inter', sans-serif;
    --heading-font: 'Plus Jakarta Sans', sans-serif;
    --primary-color: #2563eb;
    --surface-color: #ffffff;
    --background-color: #f8fafc;
    --text-primary: #0f172a;
    --text-secondary: #475569;
    --border-color: #e2e8f0;
  }

  body {
    background-color: var(--background-color);
    color: var(--text-primary);
    font-family: var(--bs-body-font-family);
    -webkit-font-smoothing: antialiased;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: var(--heading-font);
  }

  @media print {
    body {
      background: white;
    }
  }
`;

export const OrderContainer = styled.div`
  padding: 1rem 0.75rem;
  
  @media (min-width: 768px) {
    padding: 3rem 1.25rem;
  }
`;

export const OrderCard = styled.div`
  background: var(--surface-color);
  border-radius: 1rem;
  border: 1px solid var(--border-color);
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);

  @media print {
    box-shadow: none;
    border: none;
  }
`;



export const CompanyLogo = styled.div`
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 0.75rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => props.hasFallback ? 'var(--primary-color)' : 'transparent'};
  color: white;
  flex-shrink: 0;  // Prevent logo from shrinking

  @media (max-width: 768px) {
    width: 2.75rem;    // Smaller size for mobile
    height: 2.75rem;
    border-radius: 0.5rem;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    background: transparent;
  }
`;

export const CompanySection = styled.div`
  padding: 1.5rem;
  
  @media (max-width: 768px) {
    padding: 1rem;
    
    .d-flex.gap-3 {
      gap: 0.5rem !important;  // Reduce the gap between logo and content
      align-items: flex-start; // Align items to top
    }
    
    .company-details {
      margin-top: 0.25rem;  // Reduce top margin
    }
  }
`;


export const CompanyInfo = styled.div`
  .company-name {
    margin-bottom: 0.75rem;
    font-size: 1.25rem;
    font-weight: 600;
    color: var(--text-primary);
  }

  .company-details {
    color: var(--text-secondary);
    font-size: 0.875rem;
    line-height: 1.5;
  }

  .contact-item {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    margin-bottom: 0.5rem;
    
    svg {
      flex-shrink: 0;
      color: var(--primary-color);
    }
  }
  
    @media (max-width: 768px) {
    // Make container take full width minus logo width
    flex: 1;
    min-width: 0; // This prevents flex item from overflowing
    
    .company-name {
      font-size: 1rem;
      margin-bottom: 0.25rem;
    }
    
   .company-details {
      font-size: 0.813rem;
      line-height: 1.4;
      padding-left: 0; // Remove any default padding
    }
    .contact-item {
      gap: 0.5rem;
      margin-bottom: 0.25rem;
      
      svg {
        width: 14px;
        height: 14px;
      }
    }
  }
`;

export const OrderHeader = styled.div`
  text-align: right;
  padding-top: 0.5rem;
  
  h2 {
    margin-bottom: 1rem;
    font-size: 1.75rem;
    letter-spacing: 0.025em;
    color: var(--primary-color);
    font-weight: 700;
  }

  .order-meta {
    color: var(--text-secondary);
    font-size: 0.875rem;

    > div {
      margin-bottom: 0.5rem;
      display: flex;
      justify-content: flex-end;
      gap: 0.75rem;
      
      span:first-child {
        color: var(--text-primary);
        font-weight: 500;
      }
    }
  }
  
  @media (max-width: 768px) {
    text-align: left;
    margin-top: 1.5rem;
    padding-top: 1.5rem;
    border-top: 1px solid var(--border-color);
    
    .order-meta > div {
      justify-content: flex-start;
    }
  }
`;

export const BillToSection = styled.div`
  padding: 1rem;
  background-color: var(--background-color);
  border-top: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);

  h6 {
    color: var(--text-secondary);
    margin-bottom: 0.75rem;
  }

  .contact-info {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
`;

export const ProductsTable = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  margin-bottom: 1.5rem;

  th, td {
    padding: 0.75rem;
    vertical-align: middle;
    border-bottom: 1px solid #dee2e6;
  }

  th {
    font-weight: 500;
    background-color: #f8f9fa;
  }

  td:last-child {
    min-width: 200px; // Ensure space for gallery
    max-width: 300px; // Limit gallery width
  }
`;

export const MobileProductCard = styled.div`
  padding: 1rem;
  border: 1px solid #dee2e6;
  border-radius: 0.375rem;
  margin-bottom: 1rem;

  .product-name {
    font-weight: 500;
    margin-bottom: 0.5rem;
  }

  .product-meta {
    font-size: 0.875rem;
    color: #6c757d;
    margin-bottom: 0.5rem;
  }

  .gallery-container {
    margin-top: 0.5rem;
    border-top: 1px solid #dee2e6;
    padding-top: 0.5rem;
  }
`;

export const SummarySection = styled.div`
  padding: 1rem 1.5rem;
  background-color: var(--surface-color);
  border-top: 1px solid var(--border-color);
  padding: 1.5rem;
  background-color: var(--background-color);
  border-top: 1px solid var(--border-color);

  .summary-row {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0;
    
    &.subtotal {
      font-weight: 500;
    }

    &.section-divider {
      border-top: 1px dashed var(--border-color);
      margin-top: 0.75rem;
      padding-top: 0.75rem;
    }

    &.charges {
      color: var(--text-secondary);
      font-size: 0.875rem;
    }

    &.discount {
      color: #16a34a;
    }

    &.payment-info {
      background-color: var(--surface-color);
      padding: 0.75rem;
      border-radius: 0.5rem;
      margin: 0.5rem 0;
    }

    &.total {
      border-top: 2px solid var(--border-color);
      margin-top: 1rem;
      padding-top: 1rem;
      font-weight: 600;
      font-size: 1.25rem;
    }

    .label {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }

    .amount {
      font-variant-numeric: tabular-nums;
    }
  }

  .notes-section {
    background-color: var(--surface-color);
    padding: 1.25rem;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
    
    @media (max-width: 768px) {
      margin-top: 1.5rem;
    }

    h6 {
      color: var(--text-primary);
      font-weight: 500;
      font-size: 0.875rem;
      text-transform: uppercase;
      letter-spacing: 0.025em;
      margin-bottom: 0.75rem;
    }

    .notes-content {
      color: var(--text-secondary);
      font-size: 0.875rem;
      line-height: 1.5;
      white-space: pre-wrap;
      
      p {
        margin-bottom: 0.5rem;
        
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
`;

export const FooterSection = styled.div`
  padding: 1rem;
  border-top: 1px solid var(--border-color);

  h6 {
    margin-bottom: 0.75rem;
  }

  .details {
    color: var(--text-secondary);
    font-size: 0.875rem;

    > div {
      margin-bottom: 0.5rem;
    }
  }
`;