import React, { useState, useEffect, useRef } from 'react';
import { X, ChevronLeft, ChevronRight, ImageOff } from 'lucide-react';

const constructImageUrl = (filePath) => {
  console.log('Constructing image URL:', filePath);
  if (!filePath) return null;
  return `https://all-user-version-4.s3.us-east-1.amazonaws.com/${filePath}`;
};

const ImageWithFallback = ({ src, alt, className }) => {
  const [error, setError] = useState(false);

  if (error || !src) {
    return (
      <div className="d-flex align-items-center justify-content-center h-100 w-100 bg-light">
        <ImageOff className="text-secondary" size={24} />
      </div>
    );
  }

  return (
    <img
      src={src}
      alt={alt}
      className={className}
      onError={() => setError(true)}
      loading="lazy"
    />
  );
};

const ProductGallery = ({ products, onClose }) => {
  const [currentState, setCurrentState] = useState({
    productIndex: 0,
    imageIndex: 0
  });
  
  const touchRef = useRef({ startX: 0, startY: 0, startTime: 0 });
  const productsWithImages = products.filter(p => p.images?.length > 0);
  const currentProduct = productsWithImages[currentState.productIndex];
  const currentImage = currentProduct?.images?.[currentState.imageIndex];

  const handleTouchStart = (e) => {
    const touch = e.touches[0];
    touchRef.current = {
      startX: touch.clientX,
      startY: touch.clientY,
      startTime: Date.now()
    };
  };

  const handleTouchEnd = (e) => {
    const touch = e.changedTouches[0];
    const deltaX = touch.clientX - touchRef.current.startX;
    const deltaY = touch.clientY - touchRef.current.startY;
    const timeElapsed = Date.now() - touchRef.current.startTime;

    if (Math.abs(deltaY) > 100 && Math.abs(deltaX) < 50) {
      onClose();
      return;
    }

    if (Math.abs(deltaX) > 50 && timeElapsed < 300) {
      navigate(deltaX > 0 ? 'prev' : 'next');
    }
  };

  const navigate = (direction) => {
    setCurrentState(prev => {
      if (direction === 'next') {
        if (prev.imageIndex < currentProduct.images.length - 1) {
          return { ...prev, imageIndex: prev.imageIndex + 1 };
        }
        if (prev.productIndex < productsWithImages.length - 1) {
          return { productIndex: prev.productIndex + 1, imageIndex: 0 };
        }
      } else {
        if (prev.imageIndex > 0) {
          return { ...prev, imageIndex: prev.imageIndex - 1 };
        }
        if (prev.productIndex > 0) {
          const prevProduct = productsWithImages[prev.productIndex - 1];
          return {
            productIndex: prev.productIndex - 1,
            imageIndex: prevProduct.images.length - 1
          };
        }
      }
      return prev;
    });
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      switch (e.key) {
        case 'ArrowLeft': navigate('prev'); break;
        case 'ArrowRight': navigate('next'); break;
        case 'Escape': onClose(); break;
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [currentState]);

  if (!currentProduct || !currentImage) return null;

  return (
    <div className="fixed-top vh-100" style={{ 
      backgroundColor: 'rgba(0, 0, 0, 0.85)',
      backdropFilter: 'blur(8px)'
    }}>
      <div className="h-100 d-flex flex-column" style={{ backgroundColor: 'transparent' }}>
    {/* Header */}
    <div className="py-3 px-4">
  <div className="d-flex justify-content-end">
    <button 
      onClick={onClose}
      className="btn text-white p-2"
    >
      <X size={20} />
    </button>
  </div>

  {/* Content without the close button */}
  <div className="d-flex flex-column">
    {/* Product Name */}
    <h5 className="text-white mb-2">
      {currentProduct.name}
    </h5>
    
    {/* Price */}
    <div className="text-white opacity-75 mb-2">
      ₹{Number(currentProduct.unitPrice).toLocaleString('en-IN')}
    </div>
    
    {/* Description */}
    {currentProduct.description && (
      <p className="text-white-50 mb-0 small">
        {currentProduct.description}
      </p>
    )}
  </div>
</div>

       {/* Main image container */}
       <div className="flex-grow-1 position-relative overflow-hidden"
             onTouchStart={handleTouchStart}
             onTouchEnd={handleTouchEnd}>
          
          {/* Left Navigation Button */}
          <div className="position-absolute start-0 top-0 h-100 z-10 d-flex align-items-center">
            <button
              onClick={() => navigate('prev')}
              className="btn h-100 px-3"
              style={{
                background: 'linear-gradient(to right, rgba(0,0,0,0.3), transparent)',
                border: 'none',
                opacity: 0.7
              }}
            >
              <ChevronLeft size={24} className="text-white" />
            </button>
          </div>

          {/* Centered Image */}
          <div className="h-100 d-flex align-items-center justify-content-center">
            <ImageWithFallback
              src={constructImageUrl(currentImage.filePath)}
              alt={`${currentProduct.name} - Image ${currentState.imageIndex + 1}`}
              className="mw-100 mh-100 object-fit-contain"
            />
          </div>

          {/* Right Navigation Button */}
          <div className="position-absolute end-0 top-0 h-100 z-10 d-flex align-items-center">
            <button
              onClick={() => navigate('next')}
              className="btn h-100 px-3"
              style={{
                background: 'linear-gradient(to left, rgba(0,0,0,0.3), transparent)',
                border: 'none',
                opacity: 0.7
              }}
            >
              <ChevronRight size={24} className="text-white" />
            </button>
          </div>
        </div>

        {/* Thumbnail strip */}
       <div className="bg-black">
  <div className="py-3 px-4">
    {/* Image counter */}
    <div className="d-flex align-items-center gap-3 mb-3">
      <div className="px-3 py-1 rounded" style={{ 
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
        fontSize: '0.9rem',
        fontWeight: 500
      }}>
        <span className="text-white-50">Image</span>
        <span className="text-white ms-2">
          {currentState.imageIndex + 1} / {currentProduct.images.length}
        </span>
      </div>
    </div>
    {/* Thumbnails */}
    <div className="d-flex gap-3 overflow-auto"
              style={{ 
                scrollbarWidth: 'none',
                msOverflowStyle: 'none',
                WebkitScrollbar: { display: 'none' }
              }}
            >
              {currentProduct.images.map((image, idx) => (
                <button
                  key={image.filePath}
                  onClick={() => setCurrentState(prev => ({ ...prev, imageIndex: idx }))}
                  className="position-relative flex-shrink-0 p-0 rounded-2 overflow-hidden"
                  style={{ 
                    width: '72px', 
                    height: '72px',
                    transition: 'all 0.3s ease',
                    opacity: idx === currentState.imageIndex ? 1 : 0.5,
                    transform: idx === currentState.imageIndex ? 'scale(1.05)' : 'scale(1)'
                  }}
                >
                  <ImageWithFallback
                    src={constructImageUrl(image.filePath)}
                    alt={`Thumbnail ${idx + 1}`}
                    className="w-100 h-100 object-fit-cover"
                  />
                  {idx === currentState.imageIndex && (
                    <div 
                      className="position-absolute inset-0" 
                      style={{ 
                        boxShadow: '0 0 0 2px #3b82f6 inset',
                        borderRadius: '8px'
                      }}
                    />
                  )}
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductGallery;