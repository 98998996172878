/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import { BottomSheet } from "react-spring-bottom-sheet";
import {
  createUserIntegration,
  disconnectIntegration,
  getUserIntegration,
  importFacebookPastLeads,
  getLeadList,
  updateUserIntegration,
} from "../constants/ApiCall";
import queryString from "query-string";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import moment from "moment";
import styled from "styled-components";
import { ChevronUp, ChevronDown, Eye, Settings } from "lucide-react";
import { Facebook as FacebookIcon } from "lucide-react";

const Container = styled.div`
  padding: 20px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  cursor: pointer;
`;

const BackButton = styled.div`
  display: flex;
  align-items: center;
  color: #000;
  font-size: 16px;
  font-weight: 500;
  
  svg {
    margin-right: 8px;
  }
`;

const TitleSection = styled.div`
  margin: 24px 0;
`;

const TitleWithIcon = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 12px;
`;

const FacebookIconWrapper = styled.div`
  svg {
    width: 29px;
    height: 27px;
    color: #4C88D3;
  }
`;

const Title = styled.h2`
  font-size: 20px;
  font-weight: 500;
  color: #000;
  margin: 0;
`;

const Description = styled.p`
  font-size: 14px;
  color: #000;
  margin: 0;
  margin-bottom: 16px;
`;

const ConnectionTime = styled.p`
  font-size: 14px;
  color: #000;
  margin: 0;
`;

const ManageButton = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  background: #0d6efd;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 8px;
  font-size: 14px;
`;

const PageList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const PageCard = styled.div`
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
`;

const PageHeader = styled.div`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  background: #f9fafb;
  border-bottom: ${props => props.expanded ? '1px solid #e5e7eb' : 'none'};
  
  &:hover {
    background: #f3f4f6;
  }
`;

const PageInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const IconContainer = styled.div`
  width: 40px;
  height: 40px;
  background: #e7f3ff;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0d6efd;
`;

const PageDetails = styled.div`
  h3 {
    font-size: 14px;
    font-weight: 500;
    margin: 0 0 4px 0;
  }
  p {
    font-size: 13px;
    color: #6b7280;
    margin: 0;
  }
`;

const FormList = styled.div`
  padding: ${props => props.expanded ? '16px' : '0'};
  max-height: ${props => props.expanded ? '2000px' : '0'};
  opacity: ${props => props.expanded ? 1 : 0};
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const FormItem = styled.div`
  padding: 12px 16px;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  background: white;
  min-width: 0;
  position: relative; // Add this
  padding-right: 20px; // Add this to make room for "Store leads"
  &:last-child {
    margin-bottom: 0;
  }
`;

const FormContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px; // Add padding on the right to prevent text going off-screen
`;


const FormInfo = styled.div`
  flex: 1;
  min-width: 150px; // Ensure we have enough space for form info
  h4 {
    font-size: 14px;
    font-weight: normal;
    margin: 0 0 4px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  p {
    font-size: 12px;
    color: #6b7280;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const FormActions = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  flex-shrink: 0; // Prevent actions from shrinking
`;

const StatusBadge = styled.span`
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 500;
  background: ${props => props.active ? '#dcf5e5' : '#f5f5f5'};
  color: ${props => props.active ? '#0a7c3e' : '#666'};
  white-space: nowrap;
`;

const PreviewButton = styled.button`
  background: none;
  border: none;
  padding: 4px;
  color: #6b7280;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  
  &:hover {
    color: #111827;
  }
`;

const FormManagement = styled.div`
  margin-top: 24px;
  padding-top: 24px;
  border-top: 1px solid #e5e7eb;
`;

const FormGroup = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.label`
  display: block;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
  color: #111827;
`;

const Select = styled.select`
  width: 100%;
  padding: 12px;
  border: 1px solid #E5E7EB;
  border-radius: 8px;
  background: white;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
  font-weight: 600;

  &:focus {
    outline: none;
    border-color: #4C88D3;
  }
`;

const ImportButton = styled.button`
  width: 211px;
  height: 48px;
  background: #4C88D3;
  color: white;
  border: none;
  border-radius: 15px;
  font-weight: 700;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 19px auto 50px;
  cursor: pointer;

  &:hover {
    background: #3b77c0;
  }
`;

// New toggle components
const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  max-width: 110px; // Limit the width to ensure it doesn't overflow
`;

const ToggleSwitch = styled.label`
  position: relative;
  display: inline-block;
  width: 30px; // Make the switch smaller
  height: 16px; // Make the switch smaller
`;

const ToggleInput = styled.input.attrs({ type: 'checkbox' })`
  opacity: 0;
  width: 0;
  height: 0;
  
  &:checked + span {
    background-color: #4C88D3;
  }
  
  &:checked + span:before {
    transform: translateX(16px);
  }
`;

const ToggleSlider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;
  
  &:before {
    position: absolute;
    content: "";
    height: 12px; // Smaller knob
    width: 12px; // Smaller knob
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
  }
  
  ${ToggleInput}:checked + && {
    background-color: #4C88D3;
  }
  
  ${ToggleInput}:checked + &&:before {
    transform: translateX(14px);
  }
`;

const ToggleLabel = styled.span`
  font-size: 11px;
  margin-left: 4px;
  margin-right: 45px; // Add this line to push content left
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

function Facebook(props) {
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const [integration, setIntegration] = useState(null);
  const [loadingText, setLoadingText] = useState("Loading");
  const [pastLeadsDuration, setPastLeadsDuration] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertType, setAlertType] = useState(null);
  const [leadData, setLeadData] = useState([]);
  const [integrationDetails, setIntegrationDetails] = useState({ pageDetails: [] });
  const [expandedPages, setExpandedPages] = useState(new Set());
  const [integrationDate, setIntegrationDate] = useState(null);
  const [isConnecting, setIsConnecting] = useState(false);
  useEffect(() => {
    const queryParams = queryString.parse(props.location.search);
    if (queryParams["accessToken"]) {
      handleSavePress(queryParams);
    }
    getIntegration();
  }, []);

  const getIntegration = async () => {
    try {
      setLoading(true);
      const queryParams = queryString.parse(props.location.search);
      const response = await getUserIntegration(queryParams["token"]);
      
      if (response?.status) {
        const _integration = response.data.find(
          (_i) => _i.integrationKey === "facebook"
        );
        if (_integration) {
          setIntegrationDetails(_integration.details || { pageDetails: [] });
          setIntegration(_integration);
          setIntegrationDate(_integration.createdAt);
        }
        await getLeadListData();
      }
    } catch (error) {
      NotificationManager.error(error?.message || "Error getting integration");
    } finally {
      setLoading(false);
    }
  };

  const handleToggleLeadPrevention = (pageId, formId) => {
    // Create a deep copy of the integrationDetails
    const updatedDetails = JSON.parse(JSON.stringify(integrationDetails));
    
    // Find the page and form
    const pageIndex = updatedDetails.pageDetails.findIndex(p => p.pageId === pageId);
    if (pageIndex !== -1) {
      const formIndex = updatedDetails.pageDetails[pageIndex].forms.findIndex(f => f.id === formId);
      if (formIndex !== -1) {
        // Toggle the preventLeadFetch property
        const form = updatedDetails.pageDetails[pageIndex].forms[formIndex];
        form.preventLeadFetch = !form.preventLeadFetch;
        
        // Update the state first for immediate UI response
        setIntegrationDetails(updatedDetails);
        
        // Then call API to persist the change
        handleUpdateUserIntegration(null, updatedDetails);
      }
    }
  };

  const getLeadListData = async () => {
    try {
      const params = { isAscending: false, page: 1, perPage: 15 };
      const response = await getLeadList(params);
      setLeadData(response?.status ? response.data : []);
    } catch (error) {
      NotificationManager.error("Error fetching lead data");
      setLeadData([]);
    }
  };

  const handlePastLeads = async () => {
    if (!pastLeadsDuration) {
      setShowAlert(true);
      setAlertType("error");
      setAlertMessage("Please select duration for which you want to fetch past leads");
      return;
    }

    try {
      setLoading(true);
      setLoadingText("Importing past leads...");
      const queryParams = queryString.parse(props.location.search);
      const response = await importFacebookPastLeads(pastLeadsDuration, queryParams["token"]);
      NotificationManager.success(response.message);
      setShowAlert(false);
    } catch (error) {
      NotificationManager.error(error?.message || "Error while importing leads");
    } finally {
      setLoading(false);
      setLoadingText("Loading");
    }
  };

  const handleSavePress = async (data) => {
    try {
      setLoadingText("Linking With 3Sigma");
      setLoading(true);
      const payload = {
        fbUserId: data.fb_user_id,
        fbUserAccessToken: data.accessToken,
      };
      const queryParams = queryString.parse(props.location.search);
      const response = await createUserIntegration("facebook", payload, queryParams["token"]);
      
      if (response?.status) {
        props.history.push({ pathname: `/integration/success` });
      }
    } catch (error) {
      NotificationManager.error("Error in saving integration");
    } finally {
      setLoading(false);
    }
  };

  const handleSelectLeadList = (id) => {
    handleUpdateUserIntegration(id);
  };

 
  const handleUpdateUserIntegration = async (lead_id, customDetails = null) => {
    // Create the details object first
    const detailsObj = lead_id ? 
      { ...(customDetails || integrationDetails), list: lead_id } : 
      { ...(customDetails || integrationDetails) };
      
    // Wrap it in the expected structure
    const tempObj = {
      details: detailsObj
    };
  
    try {
      const response = await updateUserIntegration(integration?.userIntegrationId, tempObj);
      if (response?.status) {
        NotificationManager.success(response.message);
      } else {
        throw new Error();
      }
    } catch (error) {
      NotificationManager.error("Error in integration");
    }
  };

  const handleDisconnectIntegration = async () => {
    try {
      setLoading(true);
      setLoadingText("Disconnecting...");
      const queryParams = queryString.parse(props.location.search);
      const response = await disconnectIntegration(
        integration?.userIntegrationId,
        queryParams["token"]
      );
      
      if (response?.status) {
        props.history.push({ pathname: `/disconnect/success` });
      } else {
        throw new Error();
      }
    } catch (error) {
      NotificationManager.error("Error in disconnecting integration");
    } finally {
      setLoading(false);
    }
  };

  const togglePage = (pageId) => {
    setExpandedPages(prev => {
      const newSet = new Set(prev);
      if (newSet.has(pageId)) {
        newSet.delete(pageId);
      } else {
        newSet.add(pageId);
      }
      return newSet;
    });
  };

  const renderNotConnected = () => (
    <div className="text-center py-5">
      <IconContainer style={{ margin: '0 auto 20px' }}>
        <FacebookIcon size={24} />
      </IconContainer>
      <h2 className="mb-3">Connect Facebook Lead Forms</h2>
      <p className="text-muted mb-4">Connect your Facebook account to manage lead forms</p>
      <ImportButton 
        onClick={() => {
          if (!isConnecting) {
            setIsConnecting(true);
            props.history.push({ pathname: `/login/facebook` });
          }
        }}
        disabled={isConnecting}
        style={{ opacity: isConnecting ? 0.7 : 1 }}
      >
        {isConnecting ? 'Connecting...' : 'Connect Facebook'}
      </ImportButton>
    </div>
  );
  

  return (
    <BottomSheet
      open={open}
      snapPoints={({ minHeight, maxHeight }) => [minHeight, maxHeight / 1]}
      defaultSnap={({ lastSnap, snapPoints }) => lastSnap ?? Math.max(...snapPoints)}
    >
      {showAlert && (
        <div className={`alert alert-${alertType}`} role="alert">
          {alertMessage}
        </div>
      )}
      <LoadingOverlay active={loading} spinner text={loadingText}>
        <Container>
          <Header onClick={() => props.history.push('/integration')}>
            <BackButton>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M15 18L9 12L15 6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
              Manage integration
            </BackButton>
          </Header>

          <TitleSection>
            <TitleWithIcon>
              <FacebookIconWrapper>
                <svg width="29" height="27" viewBox="0 0 29 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14.5 0C6.52499 0 0 6.08584 0 13.5813C0 20.3584 5.30699 25.9834 12.238 27V17.512H8.55499V13.5813H12.238V10.5858C12.238 7.18374 14.3985 5.31325 17.719 5.31325C19.2995 5.31325 20.9525 5.57078 20.9525 5.57078V8.91868H19.1255C17.3275 8.91868 16.762 9.96235 16.762 11.0331V13.5813H20.793L20.1405 17.512H16.762V27C20.1788 26.4956 23.2902 24.8659 25.5344 22.4052C27.7786 19.9445 29.0077 16.8149 29 13.5813C29 6.08584 22.475 0 14.5 0Z" fill="#4C88D3"/>
                </svg>
              </FacebookIconWrapper>
              <Title>Facebook integration</Title>
            </TitleWithIcon>
            <Description>
              Instantly receive new leads by connecting your Facebook account.
            </Description>
            {integration && (
              <ConnectionTime>
                Facebook Connected successfully on {moment(integrationDate).format('DD MMM YYYY hh:mm A')}
              </ConnectionTime>
            )}
          </TitleSection>

          {!integration ? (
  <div style={{ textAlign: 'center', marginTop: '40px' }}>
    <ImportButton 
      onClick={() => {
        if (!isConnecting) {
          setIsConnecting(true);
          props.history.push({ pathname: `/login/facebook` });
        }
      }}
      disabled={isConnecting}
      style={{ opacity: isConnecting ? 0.7 : 1 }}
    >
      {isConnecting ? 'Connecting...' : 'Connect'}
    </ImportButton>
  </div>
) : (
            <>
              <PageList>
                {integrationDetails?.pageDetails?.map((page) => {
                  const isExpanded = expandedPages.has(page.pageId);
                  const activeFormsCount = page.forms?.filter(f => f.status === 'ACTIVE').length || 0;

                  return (
                    <PageCard key={page.pageId}>
                      <PageHeader onClick={() => togglePage(page.pageId)}>
                        <PageInfo>
                          <IconContainer>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="#4C88D3">
                              <path d="M12 2C6.477 2 2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.879V14.89h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.989C18.343 21.129 22 16.99 22 12c0-5.523-4.477-10-10-10z" />
                            </svg>
                          </IconContainer>
                          <PageDetails>
                            <h3>{page.pageName}</h3>
                            <p>{page.forms?.length || 0} forms ({activeFormsCount} active)</p>
                          </PageDetails>
                        </PageInfo>
                        {isExpanded ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                      </PageHeader>

                      <FormList expanded={isExpanded}>
                        {page.forms?.map((form) => (
                          <FormItem key={form.id}>
                            <FormContent>
  <FormInfo>
    <h4>{form.name}</h4>
    <p>ID: {form.id}</p>
  </FormInfo>
  <FormActions>
    {/* StatusBadge removed from here */}
    <ToggleContainer>
      <ToggleSwitch>
        <ToggleInput
          checked={!form.preventLeadFetch}
          onChange={() => handleToggleLeadPrevention(page.pageId, form.id)}
        />
        <ToggleSlider />
        <ToggleLabel>
          {form.preventLeadFetch ? "No leads" : "Store leads"}
        </ToggleLabel>
      </ToggleSwitch>
    </ToggleContainer>
  </FormActions>
</FormContent>
                          </FormItem>
                        ))}
                      </FormList>
                    </PageCard>
                  );
                })}
              </PageList>

              <FormGroup>
                <Label>Select lead list</Label>
                <Select 
                  onChange={(e) => handleSelectLeadList(e.target.value)}
                  defaultValue=""
                >
                  <option value="">Select list</option>
                  <option value="">Default lead</option>
                  {leadData.map((item) => (
                    <option key={item._id} value={item._id}>
                      {item.name}
                    </option>
                  ))}
                </Select>
              </FormGroup>

              <FormGroup>
                <Label>Import past leads</Label>
                <Select
                  onChange={(e) => setPastLeadsDuration(e.target.value)}
                  defaultValue=""
                >
                  <option value="">None</option>
                  <option value="1">Last 24 hours leads</option>
                  <option value="7">Last 7 days leads</option>
                  <option value="30">Last 30 days leads</option>
                  <option value="90">Last 90 days leads</option>
                </Select>
              </FormGroup>

              <ImportButton onClick={handlePastLeads}>
                Import past leads
              </ImportButton>
              <ImportButton 
  onClick={handleDisconnectIntegration} 
  style={{ 
    backgroundColor: '#dc3545',  // Bootstrap-like danger red 
    marginTop: '10px' 
  }}
>
  Disconnect Facebook
</ImportButton>
            </>
          )}
        </Container>
      </LoadingOverlay>
      <NotificationContainer />
    </BottomSheet>
  );
}

export default Facebook;