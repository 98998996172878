import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import { makeStyles } from "@material-ui/core";
import { NotificationContainer, NotificationManager } from "react-notifications";
import { unsubscribeUser } from "../constants/ApiCall";
import Logo from "../images/3sigma-logo.png"; // Update with your actual logo path

function Unsubscribe() {
  const { code } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [countdown, setCountdown] = useState(5);

  useEffect(() => {
    processUnsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (success || (!loading && message)) {
      const countdownInterval = setInterval(() => {
        setCountdown((prevCount) => {
          if (prevCount <= 1) {
            clearInterval(countdownInterval);
            // Navigate back or close window
            history.push("/");
            return 0;
          }
          return prevCount - 1;
        });
      }, 1000);

      return () => clearInterval(countdownInterval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success, loading, message]);

  const processUnsubscribe = async () => {
    if (!code) {
      setLoading(false);
      setMessage("Invalid unsubscribe link. No token provided.");
      NotificationManager.error("Invalid unsubscribe link");
      return;
    }

    try {
      const response = await unsubscribeUser(code);
      setLoading(false);
      
      if (response && response.status) {
        setSuccess(true);
        setMessage("You have been successfully unsubscribed from 3Sigma CRM emails.");
        NotificationManager.success("Successfully unsubscribed");
      } else {
        setMessage(response.message || "Failed to unsubscribe. Please try again later.");
        NotificationManager.error(response.message || "Failed to unsubscribe");
      }
    } catch (error) {
      setLoading(false);
      setMessage("An error occurred. Please try again later.");
      NotificationManager.error("An error occurred");
    }
  };

  const useStyles = makeStyles(() => ({
    container: {
      maxWidth: "600px",
      margin: "0 auto",
      padding: "40px 24px",
      textAlign: "center",
      fontFamily: "Gilroy, sans-serif",
    },
    logo: {
      width: "120px",
      marginBottom: "30px",
    },
    card: {
      background: "#FFFFFF",
      borderRadius: "10px",
      boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
      padding: "30px 20px",
      marginTop: "20px",
    },
    title: {
      fontWeight: "600",
      fontSize: "24px",
      marginBottom: "20px",
      color: "#333333",
    },
    message: {
      fontSize: "16px",
      lineHeight: "24px",
      marginBottom: "20px",
      color: "#666666",
    },
    success: {
      color: "#4CAF50",
    },
    error: {
      color: "#F44336",
    },
    countdown: {
      fontSize: "14px",
      color: "#999999",
      marginTop: "30px",
    },
  }));

  const classes = useStyles();

  return (
    <LoadingOverlay active={loading} spinner text="Processing your request...">
      <div className={classes.container}>
        <img src={Logo} alt="3Sigma CRM" className={classes.logo} />
        
        <div className={classes.card}>
          <h1 className={classes.title}>
            {success ? "Unsubscribe Successful" : "Unsubscribe Status"}
          </h1>
          
          <p className={`${classes.message} ${success ? classes.success : classes.error}`}>
            {message}
          </p>
          
          {(success || (!loading && message)) && (
            <p className={classes.countdown}>
              This page will automatically close in {countdown} seconds.
            </p>
          )}
        </div>
      </div>
      <NotificationContainer />
    </LoadingOverlay>
  );
}

export default Unsubscribe;