import React, { useState,useEffect } from "react";
import { BottomSheet } from "react-spring-bottom-sheet";
import LoadingOverlay from "react-loading-overlay";
import { makeStyles } from "@material-ui/core/styles";
import { useGoogleLogin } from "@react-oauth/google";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { checkGoogleIntegration } from "../constants/ApiCall";
import axios from 'axios';
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

import Header from "../Components/Integration Components/Header";
import Integration from "../Components/Integration Components/Integration";
import Button from "../Components/Integration Components/Button";
import Logo from "../images/google_calendar.png";

const GOOGLE_CLIENT_ID = "322938904388-p7l4fso2n9g93n8irg3c9get69rn3vk4.apps.googleusercontent.com";
const REDIRECT_URI = "https://integration.3sigmacrm.com/integration/google_calendar/callback";

function GoogleCalendar(props) {
  const [open, setOpen] = useState(true);
  const [integration, setIntegration] = useState(false);
  const [loading, setLoading] = useState(true); // Start with loading true
  const [loadingText, setLoadingText] = useState("Checking integration status...");
  
  
  const isIframe = window !== window.parent;

  const getTokenFromUrl = () => {
    const url = new URL(window.location.href);
    return url.searchParams.get("token");
  };

  useEffect(() => {
    const checkIntegration = async () => {
      try {
        const token = getTokenFromUrl();
        if (!token) {
          throw new Error("Authorization token not found");
        }
        
        const integrationStatus = await checkGoogleIntegration(token);
        
        if (integrationStatus.isIntegrated && integrationStatus.scopes.calendar) {
          setIntegration(true);
        }
      } catch (error) {
        console.error("Error checking Calendar integration:", error);
      } finally {
        setLoading(false);
      }
    };
    
    checkIntegration();
  }, []);

  const googleLogin = useGoogleLogin({
    flow: "auth-code",
    scope: "https://www.googleapis.com/auth/calendar",
    redirect_uri: REDIRECT_URI,
    ux_mode: isIframe ? "redirect" : "popup",
    onSuccess: async (codeResponse) => {
      try {
        setLoading(true);
        setLoadingText("Connecting to Google Calendar...");
        
        const token = getTokenFromUrl();
        if (!token) {
          throw new Error("Authorization token not found");
        }

        const response = await axios.post('https://mapi2.3sigmacrm.com/api/v1/user/get-google-tokens', 
          { code: codeResponse.code },
          { 
            headers: { 
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          }
        );
        
        if (response.data.status) {
          setIntegration(true);
          NotificationManager.success("Calendar connected successfully!");
          
          if (isIframe) {
            window.parent.postMessage({ type: 'INTEGRATION_SUCCESS' }, '*');
            setTimeout(() => {
              window.parent.postMessage({ type: 'INTEGRATION_COMPLETE' }, '*');
            }, 1500);
          } else {
            props.history.push('/integration/success');
          }
        } else {
          throw new Error(response.data.message || "Connection failed");
        }
        
      } catch (error) {
        console.error("Calendar integration error:", error);
        NotificationManager.error(error.response?.data?.message || "Connection failed");
        if (isIframe) {
          window.parent.postMessage({ 
            type: 'INTEGRATION_ERROR',
            error: error.message 
          }, '*');
        }
      } finally {
        setLoading(false);
      }
    },
    onError: (error) => {
      console.error("Google login error:", error);
      NotificationManager.error("Authentication failed");
      setLoading(false);
      if (isIframe) {
        window.parent.postMessage({ 
          type: 'INTEGRATION_ERROR',
          error: 'Authentication failed' 
        }, '*');
      }
    }
  });

  const handleConnect = () => {
    const token = getTokenFromUrl();
    if (!token) {
      NotificationManager.error("Authorization token not found");
      return;
    }
    googleLogin();
  };

  const useStyles = makeStyles(() => ({
    container: {
      padding: "0 13px 0 17px",
      "& .integration_type": {
        gap: "12px",
      },
      "& button": {
        width: "330px",
        position: "relative",
        marginBottom: "50px !important",
        marginTop: "70px !important",
        display: "flex",
        alignItems: "center",
        paddingLeft: "33px",
        gap: "9px"
      },
    },
    text: {
      fontWeight: "600",
      fontSize: "15px",
      lineHeight: "18px",
      color: "#000000",
      margin: "0",
    }
  }));

  const classes = useStyles();

  return (
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <BottomSheet
        open={open}
        snapPoints={({ minHeight, maxHeight }) => [minHeight, maxHeight / 1]}
        defaultSnap={({ lastSnap, snapPoints }) =>
          lastSnap ?? Math.max(...snapPoints)
        }
      >
        <LoadingOverlay active={loading} spinner text={loadingText}>
          <Header setOpen={setOpen} />
          <div className={"container integration_container " + classes.container}>
            <Integration
              title={"Google Calendar"}
              logo={
                <img
                  src={Logo}
                  alt="Google Calendar"
                  width="40"
                  height="40"
                  style={{ borderRadius: "50%" }}
                />
              }
            />
            <div className={classes.text}>
              You can sync your daily tasks to Google Calendar. We will
              automatically create calendar entries for all tasks you create in
              3sigma CRM
            </div>
            
            {integration ? (
              <Button onClick={() => alert("disconnect button clicked")}>
                Disconnect
              </Button>
            ) : (
              <Button
                onClick={handleConnect}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Connect
              </Button>
            )}
          </div>
        </LoadingOverlay>
        <NotificationContainer />
      </BottomSheet>
    </GoogleOAuthProvider>
  );
}

export default GoogleCalendar;