import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import { makeStyles } from "@material-ui/core";
import Logo from "../images/3sigma-logo.png";

function LinkHandler() {
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("Redirecting you to 3Sigma CRM...");
  const [showAppStoreButtons, setShowAppStoreButtons] = useState(false);
  const [leadId, setLeadId] = useState("");
  const [isIOS, setIsIOS] = useState(false);
  const [logs, setLogs] = useState([]); // For on-screen debug logs
  const location = useLocation();
  
  // Helper to add log entries that show on screen
  const addLog = (logMessage) => {
    console.log(logMessage); // Still log to console
    setLogs(prevLogs => [...prevLogs, `${new Date().toLocaleTimeString()}: ${logMessage}`]);
  };
  
  useEffect(() => {
    addLog("LinkHandler initialized");
    addLog(`Current location: ${JSON.stringify(location)}`);
    
    // Extract the lead ID from the URL
    const params = new URLSearchParams(location.search);
    const id = params.get("leadid");
    addLog(`Extracted leadId: ${id}`);
    setLeadId(id);
    
    if (!id) {
      addLog("ERROR: No lead ID found in URL parameters");
      setMessage("Error: No lead ID found in the URL.");
      setLoading(false);
      return;
    }
    
    // Device detection
    const userAgent = navigator.userAgent;
    const isMobile = /iPhone|iPad|iPod|Android/i.test(userAgent);
    const userIsIOS = /iPhone|iPad|iPod/i.test(userAgent);
    const isSafari = /^((?!chrome|android).)*safari/i.test(userAgent);
    const isChrome = /CriOS/i.test(userAgent);
    const isFirefox = /FxiOS/i.test(userAgent);
    const isEdge = /EdgiOS/i.test(userAgent);
    
    setIsIOS(userIsIOS);
    
    // Detailed platform logs
    addLog(`Device detection - Is Mobile: ${isMobile}`);
    addLog(`Is iOS: ${userIsIOS}`);
    if (userIsIOS) {
      addLog(`iOS Browser: ${isSafari ? 'Safari' : ''}${isChrome ? 'Chrome' : ''}${isFirefox ? 'Firefox' : ''}${isEdge ? 'Edge' : ''}`);
      addLog(`iOS Version hint: ${userAgent.match(/OS (\d+)_(\d+)/)?.[0] || 'Unknown'}`);
    }
    addLog(`Is Android: ${/Android/i.test(userAgent)}`);
    addLog(`Full User Agent: ${userAgent}`);
    
    let appLaunched = false;
    
    // Track if app launches by detecting blur event
    window.addEventListener('blur', function() {
      appLaunched = true;
      addLog("Window blur detected - app may have launched");
    });
    
    if (isMobile) {
      // Try to open the app via deep link
      const deepLink = `sigmacrm://lead/${id}`;
      addLog(`Attempting to open deep link: ${deepLink}`);
      
      // For iOS, we need to take special approach due to Safari's restrictions
      if (userIsIOS) {
        addLog("iOS detected - using special approach");
        
        // For iOS, first show the buttons immediately
        // This works better because iOS often blocks automatic redirects
        setLoading(false);
        setShowAppStoreButtons(true);
        setMessage("Tap the button below to open in 3Sigma app:");
        
        // Add a special iOS-focused button that will be most effective
        setTimeout(() => {
          // Add message about what to do
          addLog("iOS: For reliable deep linking, please USE THE 'OPEN IN APP' BUTTON");
        }, 500);
      }
      // For Android, use the iframe approach which works well
      else {
        addLog("Using Android approach with iframe");
        try {
          const iframe = document.createElement('iframe');
          iframe.style.display = 'none';
          iframe.src = deepLink;
          document.body.appendChild(iframe);
          addLog("Android: Added hidden iframe with deep link");
          
          // Also try direct location approach as backup
          setTimeout(() => {
            window.location.href = deepLink;
            addLog("Android: Also tried window.location as backup");
          }, 50);
          
          // Remove iframe after a moment
          setTimeout(() => {
            if (document.body.contains(iframe)) {
              document.body.removeChild(iframe);
              addLog("Android: Removed iframe");
            }
          }, 100);
          
          // After a timeout, show buttons if app didn't launch
          setTimeout(() => {
            if (!appLaunched) {
              addLog("Deep link timeout reached (3s). App did not launch.");
              setLoading(false);
              setShowAppStoreButtons(true);
              setMessage("Open in 3Sigma app or download it from the app store:");
            } else {
              addLog("App appears to have launched successfully!");
            }
          }, 3000);
        } catch (error) {
          addLog(`ERROR attempting to open deep link: ${error.message}`);
          setLoading(false);
          setShowAppStoreButtons(true);
        }
      }
    } else {
      // On desktop, redirect to web app after a short delay
      const webAppUrl = `https://web.3sigmacrm.com/leads/${id}`;
      addLog(`Desktop detected. Redirecting to web app: ${webAppUrl}`);
      
      // Small delay to ensure logs are visible
      setTimeout(() => {
        window.location.href = webAppUrl;
      }, 1000);
    }
  }, [location]);
  
  // Handle app store button clicks
  const goToAppStore = () => {
    addLog("User clicked to go to app store");
    if (isIOS) {
      addLog("Redirecting to App Store");
      window.location.href = "https://apps.apple.com/be/app/3sigma-mobile-crm/id6463200219";
    } else {
      addLog("Redirecting to Google Play Store");
      window.location.href = "https://play.google.com/store/apps/details?id=com.threesigma";
    }
  };
  
  // Handle web app redirection
  const goToWebApp = () => {
    addLog("User clicked to go to web app");
    window.location.href = `https://web.3sigmacrm.com/leads/${leadId}`;
  };
  
  // Try deep link again
  const retryDeepLink = () => {
    const deepLink = `sigmacrm://lead/${leadId}`;
    addLog(`Manually retrying deep link: ${deepLink}`);
    
    // Try different approach for retry based on device
    if (isIOS) {
      addLog("Retry: Using iOS specific approach");
      // Use timeout to ensure log appears first
      setTimeout(() => {
        window.location.href = deepLink;
        addLog("Retry: Applied iOS deep link via location.href");
      }, 50);
    } else {
      addLog("Retry: Using Android specific approach");
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = deepLink;
      document.body.appendChild(iframe);
      addLog("Retry: Added iframe for Android");
      
      setTimeout(() => {
        if (document.body.contains(iframe)) {
          document.body.removeChild(iframe);
        }
        window.location.href = deepLink;
        addLog("Retry: Also tried direct location.href approach");
      }, 100);
    }
  };
  
  const useStyles = makeStyles(() => ({
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      minHeight: "100vh",
      padding: "20px",
      textAlign: "center",
      fontFamily: "'Gilroy', sans-serif",
    },
    logo: {
      width: "120px",
      marginBottom: "20px",
    },
    message: {
      fontSize: "18px",
      fontWeight: "600",
      color: "#333333",
      marginTop: "10px",
      marginBottom: "20px"
    },
    buttonContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "15px",
      width: "100%",
      maxWidth: "300px"
    },
    button: {
      padding: "12px 20px",
      borderRadius: "8px",
      fontWeight: "600",
      cursor: "pointer",
      border: "none",
      fontSize: "16px",
      fontFamily: "'Gilroy', sans-serif",
    },
    primaryButton: {
      backgroundColor: "#4a90e2",
      color: "white",
    },
    secondaryButton: {
      backgroundColor: "#f0f0f0",
      color: "#333333",
      border: "1px solid #dddddd"
    },
    debugLogs: {
      marginTop: "20px",
      padding: "10px",
      backgroundColor: "#f5f5f5",
      borderRadius: "5px",
      width: "100%",
      maxWidth: "600px",
      maxHeight: "200px",
      overflow: "auto",
      textAlign: "left",
      fontSize: "12px",
      fontFamily: "monospace",
      color: "#333",
      border: "1px solid #ddd"
    },
    logEntry: {
      margin: "2px 0",
      borderBottom: "1px solid #eee",
      padding: "2px 0"
    }
  }));
  
  const classes = useStyles();
  
  return (
    <LoadingOverlay active={loading} spinner text="Processing">
      <div className={classes.container}>
        <img src={Logo} alt="3Sigma CRM" className={classes.logo} />
        <div className={classes.message}>{message}</div>
        
        {showAppStoreButtons && (
          <div className={classes.buttonContainer}>
            {/* Special iOS-focused button */}
            {isIOS && (
              <button 
                className={`${classes.button} ${classes.primaryButton}`}
                style={{backgroundColor: "#FF9500", marginBottom: "15px"}}
                onClick={() => {
                  addLog("User clicked iOS-specific 'Open in App' button");
                  window.location.href = `sigmacrm://lead/${leadId}`;
                }}
              >
                Open in 3Sigma App
              </button>
            )}
            
            <button 
              className={`${classes.button} ${classes.primaryButton}`}
              onClick={goToAppStore}
            >
              {isIOS ? "Get from App Store" : "Open in Google Play"}
            </button>
            
            <button 
              className={`${classes.button} ${classes.secondaryButton}`}
              onClick={retryDeepLink}
            >
              Try Opening App Again
            </button>
            
            <button 
              className={`${classes.button} ${classes.secondaryButton}`}
              onClick={goToWebApp}
            >
              Continue to Web Version
            </button>
            
            {/* Testing Panel */}
            <div style={{marginTop: "30px", width: "100%"}}>
              <div style={{fontWeight: "bold", marginBottom: "10px", textAlign: "center"}}>
                Deep Link Testing Panel
              </div>
              
              <button 
                className={`${classes.button} ${classes.secondaryButton}`}
                style={{marginBottom: "8px", backgroundColor: "#e6f7ff"}}
                onClick={() => {
                  addLog("TEST: Simple location.href approach");
                  window.location.href = `sigmacrm://lead/${leadId}`;
                }}
              >
                Test: Direct location.href
              </button>
              
              <button 
                className={`${classes.button} ${classes.secondaryButton}`}
                style={{marginBottom: "8px", backgroundColor: "#e6f7ff"}}
                onClick={() => {
                  addLog("TEST: iframe approach");
                  const iframe = document.createElement('iframe');
                  iframe.style.display = 'none';
                  iframe.src = `sigmacrm://lead/${leadId}`;
                  document.body.appendChild(iframe);
                  setTimeout(() => document.body.removeChild(iframe), 100);
                }}
              >
                Test: Hidden iframe
              </button>
              
              <button 
                className={`${classes.button} ${classes.secondaryButton}`}
                style={{marginBottom: "8px", backgroundColor: "#e6f7ff"}}
                onClick={() => {
                  addLog("TEST: a tag with click");
                  const a = document.createElement('a');
                  a.href = `sigmacrm://lead/${leadId}`;
                  a.style.display = 'none';
                  document.body.appendChild(a);
                  a.click();
                  setTimeout(() => document.body.removeChild(a), 100);
                }}
              >
                Test: Hidden a-tag click
              </button>
              
              <button 
                className={`${classes.button} ${classes.secondaryButton}`}
                style={{marginBottom: "8px", backgroundColor: "#e6f7ff"}}
                onClick={() => {
                  addLog("TEST: window.open approach");
                  window.open(`sigmacrm://lead/${leadId}`, '_system');
                }}
              >
                Test: window.open
              </button>
              
              <button 
                className={`${classes.button} ${classes.secondaryButton}`}
                style={{marginBottom: "8px", backgroundColor: "#e6f7ff"}}
                onClick={() => {
                  addLog("TEST: Delayed location approach");
                  setTimeout(() => {
                    window.location.href = `sigmacrm://lead/${leadId}`;
                    addLog("TEST: Applied delayed location");
                  }, 300);
                }}
              >
                Test: Delayed location.href
              </button>
              
              <button 
                className={`${classes.button} ${classes.secondaryButton}`}
                style={{marginBottom: "8px", backgroundColor: "#e6f7ff"}}
                onClick={() => {
                  addLog("TEST: Universal link attempt");
                  window.location.href = `https://app.sigmacrm.com/lead/${leadId}`;
                }}
              >
                Test: Universal Link
              </button>
              
              <button 
                className={`${classes.button} ${classes.secondaryButton}`}
                style={{marginBottom: "8px", backgroundColor: "#e6f7ff"}}
                onClick={() => {
                  addLog("TEST: Combined approach (iframe + location)");
                  const iframe = document.createElement('iframe');
                  iframe.style.display = 'none';
                  iframe.src = `sigmacrm://lead/${leadId}`;
                  document.body.appendChild(iframe);
                  
                  setTimeout(() => {
                    window.location.href = `sigmacrm://lead/${leadId}`;
                    addLog("TEST: Added location after iframe");
                    setTimeout(() => document.body.removeChild(iframe), 100);
                  }, 100);
                }}
              >
                Test: iframe + location
              </button>
            </div>
          </div>
        )}
        
        {/* Always show debug logs on screen */}
        <div className={classes.debugLogs}>
          <div style={{fontWeight: "bold", marginBottom: "5px"}}>Debug Logs:</div>
          {logs.map((log, index) => (
            <div key={index} className={classes.logEntry}>{log}</div>
          ))}
        </div>
      </div>
    </LoadingOverlay>
  );
}

export default LinkHandler;