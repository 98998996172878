import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import axios from 'axios';
import { 
  Phone, 
  Mail, 
  Globe, 
  Linkedin, 
  Facebook, 
  Instagram, 
  MessageCircle,
  UserPlus,
  Building2,
  Share2
} from 'lucide-react';

// Ensure axios is configured to handle CORS and errors
axios.defaults.baseURL = 'https://mapi2.3sigmacrm.com/api/v1';
axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.headers.common['Content-Type'] = 'application/json';


const DigitalCard = () => {
  const location = useLocation();
  const { uniqueLinkId } = useParams();
  const [cardData, setCardData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
 
  const { uniqueLinkId: routeUniqueId } = useParams(); // Explicitly extract uniqueLinkId from route params
  const BUCKET_URL = 'https://all-user-version-4.s3.us-east-1.amazonaws.com';
  useEffect(() => {
    console.error('FULL LOCATION:', location);
    console.error('UNIQUE LINK ID:', uniqueLinkId);
  }, [location, uniqueLinkId]);

  const generateVCF = (data) => {
    try {
      const vCardData = `BEGIN:VCARD
VERSION:3.0
FN:${data.name}
ORG:${data.companyName}
TITLE:${data.designation}
TEL:${data.phone}
EMAIL:${data.email}
URL:${data.website || ''}
END:VCARD`;

      const blob = new Blob([vCardData], { type: 'text/vcard' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${data.name}.vcf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('VCF Generation failed:', error);
    }
  };

  const ContactButton = ({ icon: Icon, label, value, href, color }) => (
    <a
      href={href}
      className="w-100 text-decoration-none mb-3"
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className="d-flex align-items-center p-3 rounded-4 bg-white shadow-sm mb-3 border-start border-5 border-primary-subtle hover-card">
  <div className={`rounded-circle me-3 bg-${color}-subtle p-3 d-flex align-items-center justify-content-center`} style={{ width: '48px', height: '48px' }}>
    <Icon size={20} className={`text-${color}`} />
  </div>
  <div className="flex-grow-1 text-truncate">
    <div className="text-muted small text-uppercase letter-spacing-1">{label}</div>
    <div className="text-dark fw-medium text-truncate">{value}</div>
  </div>
</div>
    </a>
  );


  

  useEffect(() => {
    const fetchCardDetails = async () => {
      try {
        console.error('DigitalCard: Starting fetch process');
        console.error('Route Unique ID:', routeUniqueId);
        console.error('Current location:', location);
        
        // Log the current URL details
        console.error('Current pathname:', location.pathname);
        console.error('Current search:', location.search);

        // Validate uniqueLinkId
        if (!routeUniqueId) {
          console.error('DigitalCard: No unique link identifier found');
          setError('Missing unique link identifier');
          setLoading(false);
          return;
        }

        // Prepare API call
        console.error('Making API call with ID:', routeUniqueId);

        // Make API call to public endpoint
        const response = await axios.get('/digital-card/get-by-unique-link', {
          params: { uniqueLinkId: routeUniqueId }
        });

        console.error('Full API Response:', response);

        // Check response structure
        if (response.data?.status && response.data?.data) {
          console.error('Card data received:', response.data.data);
          setCardData(response.data.data);
        } else {
          console.error('DigitalCard: Invalid response structure', response.data);
          setError('Failed to fetch card data');
        }
      } catch (error) {
        console.error('DigitalCard: Full error details', error);
        console.error('Error details:', 
          error.response?.data || 
          error.message || 
          'Unknown error'
        );
        setError(
          error.response?.data?.message || 
          error.message || 
          'Failed to load card details'
        );
      } finally {
        setLoading(false);
      }
    };

    // Immediate invocation
    fetchCardDetails();
  }, [routeUniqueId]);

  if (loading) {
    return (
      <div className="min-vh-100 d-flex align-items-center justify-content-center" 
           style={{ background: 'linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)' }}>
        <div className="text-center">
          <div className="spinner-grow text-primary mb-3" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
          <div className="text-muted">Loading digital card...</div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-vh-100 d-flex align-items-center justify-content-center bg-light">
        <div className="text-center p-4">
          <div className="text-danger mb-3">
            <svg width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
              <circle cx="12" cy="12" r="10" />
              <line x1="12" y1="8" x2="12" y2="12" />
              <line x1="12" y1="16" x2="12" y2="16" />
            </svg>
          </div>
          <h2 className="h5 mb-3">Error Loading Card</h2>
          <p className="text-muted small">{error}</p>
          <p className="text-muted small">Please check the URL and try again.</p>
        </div>
      </div>
    );
  }

  if (!cardData) {
    return (
      <div className="min-vh-100 d-flex align-items-center justify-content-center bg-light">
        <div className="text-center p-4">
          <h2 className="h5">Card not found or access denied</h2>
        </div>
      </div>
    );
  }

  return (
    <div className="min-vh-100 bg-white">
    <div className="container-fluid p-0">
      <div className="mx-auto position-relative" style={{ maxWidth: '480px' }}>
          {/* Header Section */}
          <div className="position-relative">
          <div className="bg-primary bg-gradient rounded-bottom-5 text-white px-4 pt-4 pb-6">
              <div className="position-absolute top-0 end-0 p-3">
                <button 
                  className="btn btn-light btn-sm rounded-circle shadow-sm" 
                  onClick={() => {
                    console.log('Share button clicked');
                  }}
                >
                  <Share2 size={18} />
                </button>
              </div>
            </div>
            
            {/* Profile Image Overlap */}
            <div className="text-center" style={{ marginTop: '6px' }}>
  <div className="position-relative d-inline-block">
  <div 
      className="rounded-circle p-1 bg-white shadow-lg overflow-hidden" 
      style={{ 
        width: '100px', 
        height: '100px', 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center',
        marginTop: '6px'  // Added margin-top to push the div down
      }}
    >
      <img
        src={`${BUCKET_URL}/${cardData?.profile?.filePath}`}
        alt={`${cardData?.name}'s profile`}
        className="rounded-circle"
        style={{ 
          maxWidth: '100%', 
          maxHeight: '100%', 
          objectFit: 'cover', 
          width: '100%', 
          height: '100%' 
        }}
      />
    </div>
  </div>
</div>
          </div>

          {/* Content Section */}
          <div className="px-4 pt-3">
            <div className="text-center mb-4">
              <h1 className="h4 mb-1">{cardData?.name}</h1>
              <div className="text-primary fw-medium mb-1">{cardData?.designation}</div>
              <div className="text-muted small mb-3">{cardData?.companyName}</div>
              <p className="text-secondary small px-4">{cardData?.briefDescription}</p>
            </div>

            {/* Contact Buttons */}
            <div className="mb-4">
              <ContactButton
                icon={Phone}
                label="Phone"
                value={cardData?.phone}
                href={`tel:${cardData?.phone}`}
                color="primary"
              />
              <ContactButton
                icon={MessageCircle}
                label="WhatsApp"
                value={cardData?.whatsapp}
                href={`https://wa.me/${cardData?.whatsapp}`}
                color="success"
              />
              <ContactButton
                icon={Mail}
                label="Email"
                value={cardData?.email}
                href={`mailto:${cardData?.email}`}
                color="danger"
              />
              {cardData?.website && (
                <ContactButton
                  icon={Globe}
                  label="Website"
                  value={cardData?.website}
                  href={cardData?.website}
                  color="info"
                />
              )}
            </div>

            {/* Social Links */}
           {/* Social Links */}
           <div className="d-flex justify-content-center gap-3 mb-4">
              {cardData?.linkedIn && (
                <div className="bg-white rounded-pill shadow-sm p-2" style={{ width: '120px', height: '44px' }}>
                  <a
                    href={cardData.linkedIn}
                    className="d-flex align-items-center justify-content-center gap-2 text-decoration-none"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Linkedin size={20} className="text-primary" />
                    <span className="text-primary small">LinkedIn</span>
                  </a>
                </div>
              )}
              {cardData?.facebook && (
                <div className="bg-white rounded-pill shadow-sm p-2" style={{ width: '120px', height: '44px' }}>
                  <a
                    href={cardData.facebook}
                    className="d-flex align-items-center justify-content-center gap-2 text-decoration-none"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Facebook size={20} className="text-primary" />
                    <span className="text-primary small">Facebook</span>
                  </a>
                </div>
              )}
              {cardData?.instagram && (
                <div className="bg-white rounded-pill shadow-sm p-2" style={{ width: '120px', height: '44px' }}>
                  <a
                    href={cardData.instagram}
                    className="d-flex align-items-center justify-content-center gap-2 text-decoration-none"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Instagram size={20} className="text-danger" />
                    <span className="text-danger small">Instagram</span>
                  </a>
                </div>
              )}
            </div>

            {/* Add to Contacts */}
            <div className="text-center mb-4">
              <button
                onClick={() => {
                  console.log('Save contact clicked');
                  generateVCF(cardData);
                }}
                className="btn btn-primary rounded-pill px-4 py-3 shadow-sm d-flex align-items-center justify-content-center gap-2 w-75 mx-auto fw-medium"
              >
                <UserPlus size={18} className="me-2" />
                Save Contact
              </button>
            </div>

            {/* Footer */}
            <div className="border-top pt-4 pb-5 mt-4">
              <div className="d-flex align-items-center justify-content-center text-muted small">
                <Building2 size={16} className="me-2" />
                {cardData?.companyAddress}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Custom CSS */}
      <style jsx>{`
        .hover-lift {
          transition: transform 0.2s ease, box-shadow 0.2s ease;
        }
        .hover-lift:hover {
          transform: translateY(-2px);
          box-shadow: 0 0.5rem 1rem rgba(0,0,0,.1) !important;
        }
        .icon-circle {
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
        }
        .pb-7 {
          padding-bottom: 5rem;
        }
        .contact-card {
          transition: all 0.2s ease;
        }
        .contact-card:hover {
          background-color: var(--bs-primary-bg-subtle);
        }
          .shadow-hover {
  transition: all 0.2s ease;
}
.shadow-hover:hover {
  box-shadow: 0 0.5rem 1rem rgba(0,0,0,.08);
  transform: translateY(-2px);
}
.transition-all {
  transition: all 0.2s ease;
}
      `}</style>
    </div>
  );
};

export default DigitalCard;