import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { uploadFiles } from "../constants/ApiCall";



/**
 * Improved Direct PDF generator for invoices
 * Creates professional, well-aligned PDFs directly from invoice data
 */
class DirectInvoicePdfGenerator {
  static DEBUG = true;
  static LOGO_DEBUG = true;
  
  static log(message, data = null) {
    if (this.DEBUG) {
      console.log(`[DirectPDF] ${message}`, data || '');
    }
    
    if (this.LOGO_DEBUG && message.toLowerCase().includes('logo')) {
      console.log(`[LogoDebug] ${message}`, data || '');
    }
  }

  /**
   * Format currency with commas (Indian format)
   */
  static formatCurrency(value) {
    if (value === null || value === undefined) return '0';
    return new Intl.NumberFormat('en-IN').format(Number(value) || 0);
  }

  /**
   * Convert amount to words
   */
  static amountToWords(amount) {
    if (!amount) return '';
    
    const units = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine', 'Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'];
    const tens = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];
    
    function convertNumberToWords(num) {
      if (num < 20) return units[num];
      if (num < 100) return tens[Math.floor(num / 10)] + (num % 10 ? ' ' + units[num % 10] : '');
      if (num < 1000) return units[Math.floor(num / 100)] + ' Hundred' + (num % 100 ? ' ' + convertNumberToWords(num % 100) : '');
      if (num < 100000) return convertNumberToWords(Math.floor(num / 1000)) + ' Thousand' + (num % 1000 ? ' ' + convertNumberToWords(num % 1000) : '');
      if (num < 10000000) return convertNumberToWords(Math.floor(num / 100000)) + ' Lakh' + (num % 100000 ? ' ' + convertNumberToWords(num % 100000) : '');
      return convertNumberToWords(Math.floor(num / 10000000)) + ' Crore' + (num % 10000000 ? ' ' + convertNumberToWords(num % 10000000) : '');
    }
    
    // Handle integer and decimal parts
    const [intPart, decPart] = amount.toString().split('.');
    const intWords = convertNumberToWords(parseInt(intPart));
    const decWords = decPart ? convertNumberToWords(parseInt(decPart)) : '';
    
    // Format the final string
    if (decWords) {
      return `${intWords} Rupees and ${decWords} Paise Only`;
    } else {
      return `${intWords} Rupees Only`;
    }
  }

  /**
   * Add organization logo to PDF
   */
  static async addLogoToPdf(doc, logoUrl, x, y, maxWidth = 40) {
    if (!logoUrl) {
      this.log('No logo URL provided, skipping logo addition');
      return { success: false, newY: y };
    }
    
    this.log('Attempting to add logo to PDF', { logoUrl });
    
    // Create a promise with timeout
    const loadImageWithTimeout = (url, timeoutMs = 5000) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = 'Anonymous';
        
        const timeoutId = setTimeout(() => {
          this.log('Logo loading timed out', { url });
          reject(new Error('Logo loading timed out'));
        }, timeoutMs);
        
        img.onload = () => {
          clearTimeout(timeoutId);
          this.log('Logo loaded successfully', { width: img.width, height: img.height });
          resolve(img);
        };
        
        img.onerror = () => {
          clearTimeout(timeoutId);
          this.log('Logo failed to load', { url });
          reject(new Error('Logo failed to load'));
        };
        
        img.src = url;
      });
    };
    
    try {
      const img = await loadImageWithTimeout(logoUrl);
      
      // Calculate dimensions preserving aspect ratio
      const ratio = img.height / img.width;
      const width = Math.min(maxWidth, img.width);
      const height = width * ratio;
      
      this.log('Adding logo to PDF', { width, height, x, y });
      
      // Add image to PDF
      doc.addImage(img, 'JPEG', x, y, width, height);
      
      return { success: true, newY: y + height + 5 };
    }  catch (error) {
  this.log('Failed to add logo, continuing without it', { error: error.message });
  return { success: false, newY: y }; // Just return current position
}
  }

  /**
   * Add styled text to PDF
   */
  static addStyledText(doc, text, x, y, options = {}) {
    const {
      fontSize = 10,
      fontStyle = 'normal',
      align = 'left',
      color = [0, 0, 0],
      maxWidth = 170
    } = options;
    
    // Set styles
    doc.setFontSize(fontSize);
    doc.setFont('helvetica', fontStyle);
    doc.setTextColor(...color);
    
    // Handle text wrapping
    const splitText = doc.splitTextToSize(text, maxWidth);
    
    // Add text with alignment
    doc.text(splitText, x, y, { align });
    
    // Return new Y position
    return y + (splitText.length * fontSize * 0.352778); // Convert pt to mm
  }

  /**
   * Add header to each page
   */
 

  /**
   * Generate a PDF directly from invoice data
   */
  static async generatePDF(invoiceData) {
    this.log('Starting direct PDF generation', { invoiceId: invoiceData.invoiceId });
    
    // Create PDF document
    const doc = new jsPDF({
      orientation: 'portrait',
      unit: 'mm',
      format: 'a4',
      compress: true
    });
    
    // Add custom font
    doc.setFont('helvetica');
    
    try {
      // Extract basic info
      const {
        organization = {},
        lead = {},
        products = [],
        bankDetails = {},
        invoiceId = '',
        createdAt = new Date(),
        createdBy = '',
        subTotal = 0,
        total = 0,
        discount = null,
        taxes = [],
        additionalCharges = [],
        notes = '',
        termsAndCondition = ''
      } = invoiceData;
      
      // Format date
      const formattedDate = new Date(createdAt).toLocaleDateString('en-IN', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      });
      
      // Determine currency symbol
      const currencySymbol = products[0]?.currency === "₹" ? "Rs " : (products[0]?.currency || "Rs ");
      
      // Set margins and positions
      const margin = 12;
      const pageWidth = 210; // A4 width in mm
      const contentWidth = pageWidth - 2 * margin;
      
      // ===== HEADER SECTION WITH IMPROVED LAYOUT =====
     
      let yPos = margin;
      
      // Add organization logo if available
      if (organization && organization.iconUrl) {
        try {
          const logoUrl = organization.iconUrl;
          this.log('Organization logo found in iconUrl', { logoUrl });
          
          // Build the correct URL with bucket prefix
          const BUCKET_URL = 'https://all-user-version-4.s3.us-east-1.amazonaws.com/';
          const fullLogoUrl = `${BUCKET_URL}${logoUrl}`;
          
          this.log('Using full logo URL', { fullLogoUrl });
          
          // Try to add the logo
          const logoResult = await this.addLogoToPdf(doc, fullLogoUrl, margin, yPos, 40);
          
          if (logoResult.success) {
            // Update yPos if logo was added successfully
            yPos = logoResult.newY;
            this.log('Logo added successfully, new Y position:', yPos);
          }
        } catch (logoError) {
          this.log('Error handling logo', { error: logoError.message });
        }
      }

      // Company details and invoice title in two-column layout
      // Left column: Company details
      const companyNameYPos = yPos;
      doc.setFontSize(16);
      doc.setFont('helvetica', 'bold');
      doc.setTextColor(60, 60, 60);
      
      // First add the company name
      doc.text(organization.name || 'Company Name', margin, companyNameYPos);
      
      // Then try to add logo to the left of company name if available
      let logoWidth = 0;
      if (organization && organization.iconUrl) {
        try {
          const logoUrl = organization.iconUrl;
          this.log('Organization logo found in iconUrl', { logoUrl });
          
          // Build the correct URL with bucket prefix
          const BUCKET_URL = 'https://all-user-version-4.s3.us-east-1.amazonaws.com/';
          const fullLogoUrl = `${BUCKET_URL}${logoUrl}`;
          
          this.log('Using full logo URL', { fullLogoUrl });
          
          // Try to add the logo - smaller size (25 instead of 40), positioned vertically centered with company name
          const logoY = companyNameYPos - 10; // Position logo a bit higher to align with company name
          const maxLogoWidth = 25; // Smaller logo
          
          const logoResult = await this.addLogoToPdf(doc, fullLogoUrl, margin - 30, logoY, maxLogoWidth);
          
          if (logoResult.success) {
            this.log('Logo added successfully next to company name');
            logoWidth = maxLogoWidth + 5; // Add a bit of spacing
          }
        } catch (logoError) {
          this.log('Error handling logo', { error: logoError.message });
        }
      }
      
      // Right column: INVOICE title
      doc.setFontSize(20);
      doc.setTextColor(70, 70, 70);
      doc.text('INVOICE', pageWidth - margin, yPos, { align: 'right' });
      
      // Company details below company name
      yPos += 8;
      doc.setFontSize(9);
      doc.setFont('helvetica', 'normal');
      doc.setTextColor(80, 80, 80);
      
      if (organization.address) {
        doc.text(organization.address, margin, yPos);
        yPos += 5;
      }
      
      if (organization.phone) {
        doc.text(`Phone: ${organization.phone}`, margin, yPos);
        yPos += 5;
      }
      
      if (organization.website) {
        doc.text(`Website: ${organization.website}`, margin, yPos);
        yPos += 5;
      }
      
      // Invoice details on right side
      const detailsX = pageWidth - margin;
      let detailsY = yPos - 15;
      
      doc.setFontSize(9);
      doc.text(`Invoice No: ${invoiceId}`, detailsX, detailsY, { align: 'right' });
      detailsY += 5;
      
      doc.text(`Date: ${formattedDate}`, detailsX, detailsY, { align: 'right' });
      
      if (createdBy) {
        detailsY += 5;
        doc.text(`Prepared By: ${createdBy}`, detailsX, detailsY, { align: 'right' });
      }
      
    // ===== CLIENT SECTION WITH FIXED ALIGNMENT =====
yPos = Math.max(yPos, detailsY) + 10; // Reduced from 15 to decrease vertical space

// Add styled section divider
doc.setDrawColor(200, 200, 200);
doc.setLineWidth(0.5);
doc.line(margin, yPos - 5, pageWidth - margin, yPos - 5); // Reduced from -8 to -5

// Client section title - properly aligned with table
doc.setFontSize(11);
doc.setFont('helvetica', 'bold');
doc.setTextColor(60, 60, 60);
doc.text('Invoice For:', margin, yPos + 1); // Removed +5 to left-align with table

// Reduced vertical spacing
yPos += 8; // Reduced from 12 to reduce vertical space

doc.setFontSize(10);
doc.setFont('helvetica', 'normal');
doc.setTextColor(50, 50, 50);

// Display client fields in two columns with better spacing
const clientFields = [
  { key: 'name', label: 'Name' },
  { key: 'phone', label: 'Phone' },
  { key: 'email', label: 'Email' },
  { key: 'orgName', label: 'Org name' }
];

// Create two-column layout for client details
const clientCol1 = margin;
const clientCol2 = margin + (contentWidth / 2);
let clientY1 = yPos;
let clientY2 = yPos;
let colCounter = 0;

// Process client fields in columns with tighter spacing
clientFields.forEach(field => {
  if (lead[field.key] && lead[field.key] !== '') {
    if (colCounter % 2 === 0) {
      if (field.key === 'name') {
        doc.setFont('helvetica', 'bold');
        doc.text(`${field.label}: ${lead[field.key]}`, clientCol1, clientY1);
        doc.setFont('helvetica', 'normal');
      } else {
        doc.text(`${field.label}: ${lead[field.key]}`, clientCol1, clientY1);
      }
      clientY1 += 4; // Reduced spacing between lines
    } else {
      if (field.key === 'name') {
        doc.setFont('helvetica', 'bold');
        doc.text(`${field.label}: ${lead[field.key]}`, clientCol2, clientY2);
        doc.setFont('helvetica', 'normal');
      } else {
        doc.text(`${field.label}: ${lead[field.key]}`, clientCol2, clientY2);
      }
      clientY2 += 4; // Reduced spacing between lines
    }
    colCounter++;
  }
});

// Process any remaining fields
Object.entries(lead).forEach(([key, value]) => {
  if (
    value && 
    value !== '' && 
    !['_id', 'createdAt', 'updatedAt'].includes(key) &&
    !clientFields.some(field => field.key === key)
  ) {
    const label = key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, ' ');
    
    if (colCounter % 2 === 0) {
      doc.text(`${label}: ${value}`, clientCol1, clientY1);
      clientY1 += 4; // Reduced spacing between lines
    } else {
      doc.text(`${label}: ${value}`, clientCol2, clientY2);
      clientY2 += 4; // Reduced spacing between lines
    }
    colCounter++;
  }
});

// Update yPos to the maximum of both columns
yPos = Math.max(clientY1, clientY2) + 3;

// Add horizontal line with improved styling
doc.setDrawColor(220, 220, 220);
doc.setLineWidth(0.5);
doc.line(margin, yPos, pageWidth - margin, yPos);
yPos += 5; // Reduced from 8 to make spacing tighter
      
      // ===== PRODUCTS TABLE WITH IMPROVED STYLING =====
      // Table headers with better alignments
      const headers = [
        [
          { content: '#', styles: { halign: 'center', fontStyle: 'bold' } },
          { content: 'Name', styles: { halign: 'left', fontStyle: 'bold' } },
          { content: 'Description', styles: { halign: 'left', fontStyle: 'bold' } },
          { content: 'Unit Price', styles: { halign: 'right', fontStyle: 'bold' } },
          { content: 'Qty', styles: { halign: 'center', fontStyle: 'bold' } },
          { content: 'Amount', styles: { halign: 'right', fontStyle: 'bold' } }
        ]
      ];
      
      // Table body with consistent alignment
      const body = products.map((product, index) => {
        const amount = (product.quantity || 0) * (product.unitPrice || 0);
        return [
          { content: (index + 1).toString(), styles: { halign: 'center' } },
          { content: product.name || '' },
          { content: product.description || '' },
          { content: `${currencySymbol} ${this.formatCurrency(product.unitPrice)}`, styles: { halign: 'right' } },
          { content: product.quantity.toString(), styles: { halign: 'center' } },
          { content: `${currencySymbol} ${this.formatCurrency(amount)}`, styles: { halign: 'right' } }
        ];
      });
      
      // Generate table with improved styling
      doc.autoTable({
        startY: yPos,
        head: headers,
        body: body,
        theme: 'grid',
        styles: { 
          fontSize: 9,
          cellPadding: 4,
          lineColor: [220, 220, 220],
          lineWidth: 0.1
        },
        columnStyles: {
          0: { cellWidth: 10 },    // # column
          1: { cellWidth: 45 },    // Name (increased from 40)
          2: { cellWidth: 'auto' }, // Description (takes remaining space)
          3: { cellWidth: 32 },    // Unit Price (increased from 30)
          4: { cellWidth: 15 },    // Qty (unchanged)
          5: { cellWidth: 32 }     // Amount (increased from 30)
        },
        headStyles: { 
          fillColor: [240, 240, 240], 
          textColor: [50, 50, 50], 
          fontStyle: 'bold',
          lineColor: [220, 220, 220]
        },
        alternateRowStyles: { fillColor: [248, 248, 248] },
        margin: { left: margin, right: margin }
      });
      
      // Update yPos after table
    
// Update yPos after table
yPos = doc.autoTable.previous.finalY + 10;

// Check if we have at least some minimum space (30mm) to start rendering totals
// If not, create a new page
const pageHeight = doc.internal.pageSize.height;
const remainingSpace = pageHeight - yPos;
if (remainingSpace < 30) {
  doc.addPage();
  yPos = margin;
}

// Add styled divider after table
doc.setDrawColor(200, 200, 200);
doc.line(margin, yPos - 5, pageWidth - margin, yPos - 5);
      
      // ===== SUMMARY SECTION WITH IMPROVED LAYOUT =====
      // Divide space for left side (amount in words, notes) and right side (calculations)
      const summaryLeftWidth = contentWidth * 0.55;
      const calculationsX = margin + summaryLeftWidth + 5;
      const calculationsWidth = contentWidth - summaryLeftWidth - 5;
      const valueX = pageWidth - margin; // Fixed position for all right-aligned values
      
      // Add light background for calculation area
      doc.setFillColor(250, 250, 250);
      doc.roundedRect(calculationsX - 5, yPos - 5, calculationsWidth + 10, 85, 2, 2, 'F');
      
      // Initialize the calculation helper function
      const addCalcRow = (label, value, isTotal = false, indent = 0) => {
        doc.setFont('helvetica', isTotal ? 'bold' : 'normal');
        doc.text(label, calculationsX + indent, calcY);
        doc.text(value, valueX, calcY, { align: 'right' });
        calcY += isTotal ? 7 : 6; // More space for total row
      };
      
      // Start calculations section
      let calcY = yPos;
      
      // Run calculations with consistent spacing
      doc.setFontSize(10);
      doc.setTextColor(60, 60, 60);
      
      // Subtotal
      addCalcRow('Subtotal:', `${currencySymbol} ${this.formatCurrency(subTotal)}`);
      
      // Discount
      if (discount && parseFloat(discount.amount) > 0) {
        const discountLabel = discount.type === "fixed" 
          ? `Discount (fixed)` 
          : `Discount (${discount.rate}%)`;
        addCalcRow(discountLabel, `${currencySymbol} ${this.formatCurrency(discount.amount)}`);
      }
      
      // Additional charges
      if (additionalCharges && additionalCharges.length) {
        additionalCharges.forEach(charge => {
          const chargeLabel = charge.type === "fixed" 
            ? `${charge.title} (fixed)` 
            : `${charge.title} (${charge.rate}%)`;
          addCalcRow(chargeLabel, `${currencySymbol} ${this.formatCurrency(charge.amount)}`);
        });
      }
      
      // Taxes
      if (taxes && taxes.length) {
        taxes.forEach(tax => {
          addCalcRow(`${tax.title} (${tax.rate}%)`, `${currencySymbol} ${this.formatCurrency(tax.amount)}`);
        });
      }
      
  // Add separator before total
calcY += 2;
doc.setLineWidth(0.5);
doc.setDrawColor(180, 180, 180);
doc.line(calculationsX, calcY - 3, valueX, calcY - 3);
calcY += 2;

// Total with highlighted styling
doc.setFillColor(240, 240, 240);
doc.roundedRect(calculationsX - 2, calcY - 6, (valueX - calculationsX) + 4, 10, 1, 1, 'F');
doc.setTextColor(40, 40, 40);
addCalcRow('TOTAL:', `${currencySymbol} ${this.formatCurrency(total)}`, true);

// ===== PAYMENT RECEIVED SECTION =====
// Check if any payment has been received
if (invoiceData.receivedAmount && Number(invoiceData.receivedAmount) > 0) {
  // Add a small spacing after total
  calcY += 1;
  
  // Style for payment information
  doc.setFontSize(9);
  doc.setTextColor(60, 60, 60);
  doc.setFont('helvetica', 'normal');
  
  // Format payment mode with proper capitalization (cash → Cash)
  const paymentMode = invoiceData.receivedPaymentMode 
    ? invoiceData.receivedPaymentMode.charAt(0).toUpperCase() + invoiceData.receivedPaymentMode.slice(1).toLowerCase() 
    : 'Cash';
  
  // Display received amount with payment mode in parentheses
  addCalcRow(`Payment Received (${paymentMode}):`, `${currencySymbol} ${this.formatCurrency(invoiceData.receivedAmount)}`);
  
  // Calculate balance due (ensure it's never negative)
  const balanceDue = Math.max(0, Number(total) - Number(invoiceData.receivedAmount));
  
  // Add separator before balance
  calcY += 1;
  doc.setLineWidth(0.3);
  doc.setDrawColor(200, 200, 200);
  doc.line(calculationsX, calcY - 3, valueX, calcY - 3);
  calcY += 1;
  
  // Style for balance due - highlight with background
  doc.setFillColor(245, 245, 245);
  doc.roundedRect(calculationsX - 2, calcY - 6, (valueX - calculationsX) + 4, 10, 1, 1, 'F');
  doc.setFont('helvetica', 'bold');
  
  // Choose color based on balance status
  if (balanceDue <= 0) {
    // Green color for fully paid
    doc.setTextColor(22, 160, 133);
    addCalcRow('BALANCE DUE:', 'PAID', true);
  } else if (balanceDue < Number(total) * 0.25) {
    // Orange color for mostly paid
    doc.setTextColor(230, 126, 34);
    addCalcRow('BALANCE DUE:', `${currencySymbol} ${this.formatCurrency(balanceDue)}`, true);
  } else {
    // Red color for significant balance
    doc.setTextColor(192, 57, 43);
    addCalcRow('BALANCE DUE:', `${currencySymbol} ${this.formatCurrency(balanceDue)}`, true);
  }
  
  // Add additional info if balance is zero
  if (balanceDue <= 0) {
    doc.setFontSize(8);
    doc.setTextColor(22, 160, 133);
    doc.setFont('helvetica', 'normal');
    doc.text('Thank you for your payment!', calculationsX, calcY + 1);
    calcY += 4; // Add extra space for the thank you note
  }
  
} else {
  // If no payment received, just show "AMOUNT DUE" instead of balance
  calcY += 2;
  doc.setFillColor(245, 245, 245);
  doc.roundedRect(calculationsX - 2, calcY - 6, (valueX - calculationsX) + 4, 10, 1, 1, 'F');
  doc.setTextColor(192, 57, 43);
  doc.setFont('helvetica', 'bold');
  addCalcRow('AMOUNT DUE:', `${currencySymbol} ${this.formatCurrency(total)}`, true);
}
      
     // ===== AMOUNT IN WORDS SECTION (COMPACT VERSION) =====
// Position amount in words below the totals
const amountWordsY = calcY + 5;

doc.setFontSize(10);
doc.setFont('helvetica', 'bold');
doc.setTextColor(60, 60, 60);
doc.text('Amount in Words:', calculationsX, amountWordsY);

// Format the total in words
doc.setFontSize(9);
doc.setFont('helvetica', 'normal');

// Get amount in words (using the total from calculations)
const amountInWords = this.amountToWords(total);

// Split into multiple lines if needed
const amountLinesArray = doc.splitTextToSize(amountInWords, calculationsWidth - 10);
doc.text(amountLinesArray, calculationsX, amountWordsY + 7);

// Update calcY to account for the amount in words section
calcY = amountWordsY + (amountLinesArray.length * 5) + 5;
      

   // ===== BANK DETAILS SECTION WITH CONSISTENT ALIGNMENT =====
   const hasBankDetails = bankDetails && (
    bankDetails.bankName || 
    bankDetails.ifscCode || 
    bankDetails.accountNumber || 
    bankDetails.accountHolderName
  );
  
  if (hasBankDetails) {
    const bankDetailsWidth = contentWidth / 2;
  
    // Ensure consistent alignment with table
    doc.setFontSize(10);
    doc.setFont('helvetica', 'bold');
    doc.setTextColor(60, 60, 60);
    doc.text('BANK DETAILS', margin, yPos + 5);  // Use exact same margin as table
  
    doc.setFontSize(9);
    doc.setFont('helvetica', 'normal');
    doc.setTextColor(60, 60, 60);
  
    // Compact layout with label-value pairs on separate lines
    let bankY = yPos + 12;
    const bankX = margin;  // Align exactly with table edge
  
    // Add each bank detail with compact spacing
    if (bankDetails.bankName) {
      doc.text(`Name: ${bankDetails.bankName}`, bankX, bankY);
      bankY += 5;
    }
    if (bankDetails.ifscCode) {
      doc.text(`IFSC Code: ${bankDetails.ifscCode}`, bankX, bankY);
      bankY += 5;
    }
    if (bankDetails.accountNumber) {
      doc.text(`Account No: ${bankDetails.accountNumber}`, bankX, bankY);
      bankY += 5;
    }
    if (bankDetails.accountHolderName) {
      doc.text(`Account Holder: ${bankDetails.accountHolderName}`, bankX, bankY);
      bankY += 5;
    }
  
    // Update yPos to after bank details
    yPos = Math.max(yPos + 30, bankY + 5);
  } else {
    // If no bank details, just add a small space
    yPos += 5;
  }

// ===== SEPARATE NOTES SECTION WITH IMPROVED HANDLING =====
// Check if notes exist before creating the section
if (notes) {
  // Check if we need a page break for notes
  const pageHeight = doc.internal.pageSize.height;
  if (yPos + 20 > pageHeight - margin) {
    doc.addPage();
    yPos = margin;
  }
  
  // Position notes section correctly
  const notesX = margin;
  let notesY = yPos + 5; // Add some spacing
  
 
  
  doc.setFontSize(10);
  doc.setFont('helvetica', 'bold');
  doc.setTextColor(60, 60, 60);
  doc.text('Notes:', notesX, notesY);
  
  doc.setFontSize(9);
  doc.setFont('helvetica', 'normal');
  
  // Handle notes content - use full page width for notes
  const notesContent = doc.splitTextToSize(notes, contentWidth);
  doc.text(notesContent, notesX, notesY + 8);
  
  // Update position after notes
  yPos = notesY + (notesContent.length * 5) + 10;
}
      
    // ===== TERMS AND CONDITIONS WITH IMPROVED STYLING =====
// Only render terms if they exist and aren't empty
if (termsAndCondition && termsAndCondition.trim() !== '') {
  // Check if we need a page break before terms
  const pageHeight = doc.internal.pageSize.height;
  if (yPos + 20 > pageHeight - margin) {
    doc.addPage();
    yPos = margin;
  }
  
  yPos += 10;
  
  // Use full width for terms and conditions
  const termsWidth = contentWidth;
  
  doc.setFontSize(10);
  doc.setFont('helvetica', 'bold');
  doc.setTextColor(60, 60, 60);
  doc.text('Terms and Conditions:', margin, yPos);
  
  yPos += 8;
  doc.setFontSize(9); // Slightly larger font for terms
  doc.setFont('helvetica', 'normal');
  
  // Split terms into multiple lines with proper handling - using full width
  const splitTerms = doc.splitTextToSize(termsAndCondition, termsWidth);
  doc.text(splitTerms, margin, yPos);
  
  // Update y position
  yPos += (splitTerms.length * 4) + 5; // Less space between lines
}
      
     
      this.log('PDF generation completed successfully');
      return doc;
      
    } catch (error) {
      this.log('PDF generation error:', error);
      throw error;
    }
  }

  static checkPageBreak(doc, currentY, requiredSpace) {
    const pageHeight = doc.internal.pageSize.height;
    const margin = 12; // Same as your global margin
    const safetyMargin = 5;
    
    if (currentY + requiredSpace + safetyMargin > pageHeight) {
      doc.addPage();
      return margin; // Start at top of new page
    }
    
    return currentY; // Continue at current position
  }

  /**
   * Generate and download PDF
   */
  static async downloadPDF(invoiceData) {
    try {
      this.log('Starting PDF download');
      const doc = await this.generatePDF(invoiceData);
      const filename = `invoice_${invoiceData.invoiceId || 'download'}.pdf`;
      doc.save(filename);
      this.log(`PDF downloaded as ${filename}`);
      return true;
    } catch (error) {
      this.log('PDF download failed:', error);
      console.error('Failed to download PDF:', error);
      return false;
    }
  }
  
  /**
   * Generate PDF and upload to server
   */
  static async generateAndUploadPDF(invoiceData) {
    try {
      this.log('Starting PDF generation and upload');
      
      // Generate the PDF
      const doc = await this.generatePDF(invoiceData);
      
      // Convert to blob
      const pdfBlob = doc.output('blob');
      
      // Create File object
      const pdfFile = new File(
        [pdfBlob], 
        `invoice_${invoiceData._id || 'unknown'}.pdf`, 
        { type: 'application/pdf' }
      );
      
      // Prepare form data for upload
      const formData = new FormData();
      formData.append('invoice', pdfFile);
      formData.append('type', 'invoice');
      formData.append('invoice', invoiceData._id);
      
      // Upload the file
      await uploadFiles(formData);
      
      this.log('PDF uploaded successfully');
      return true;
    } catch (error) {
      this.log('PDF upload failed:', error);
      console.error('Failed to upload PDF:', error);
      return false;
    }
  }
  
  /**
   * Automatically generate and upload PDF when it's ready
   */
  static async autoGenerateAndUpload(invoiceData) {
    if (!invoiceData || !invoiceData._id) {
      this.log('Cannot auto-generate: Invalid invoice data');
      return false;
    }
    
    try {
      this.log('Auto-generating and uploading PDF', { invoiceId: invoiceData._id });
      
      // Generate and upload
      const result = await this.generateAndUploadPDF(invoiceData);
      
      if (result) {
        this.log('Auto-generate and upload completed successfully');
      } else {
        this.log('Auto-generate and upload failed');
      }
      
      return result;
    } catch (error) {
      this.log('Auto-generate and upload error:', error);
      return false;
    }
  }
}

export default DirectInvoicePdfGenerator;