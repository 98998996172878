import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Phone, Mail, Globe, Download, Share2, FileText, ChevronRight, FileImage } from 'lucide-react';
import ProductGallery from '../../Components/ProductGallery';
import {
  GlobalStyle,
  OrderContainer,
  OrderCard,

  CompanyLogo,
  CompanySection,
  CompanyInfo,
  OrderHeader,
  BillToSection,
  ProductsTable,
  MobileProductCard,
  SummarySection,
  FooterSection
} from './OrderSharedViewStyles';

const CompanyLogoSection = ({ organization }) => {
  const [imgError, setImgError] = useState(false);
  const fallbackLetter = organization?.name?.[0] || 'M';
  
  if (!organization?.iconUrl || imgError) {
    console.log('Using fallback logo with letter:', fallbackLetter);
    return (
      <CompanyLogo>
        <span className="h5 mb-0">
          {fallbackLetter}
        </span>
      </CompanyLogo>
    );
  }
  
  const logoUrl = `https://all-user-version-4.s3.us-east-1.amazonaws.com/organization_icon_url/${organization.iconUrl.split('/')[1]}/${organization.iconUrl.split('/')[2]}`;
  console.log('Attempting to load logo from:', logoUrl);
  
  return (
    <CompanyLogo>
      <img 
        src={logoUrl}
        alt="Company Logo" 
        onError={(e) => {
          console.error('Company logo load error:', e);
          setImgError(true);
        }}
      />
    </CompanyLogo>
  );
};

const OrderSharedView = () => {
  console.log('OrderSharedView Component Mounted');
  const { uniqueLinkId } = useParams();
  const [orderData, setOrderData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  const [selectedProductIndex, setSelectedProductIndex] = useState(null);

  useEffect(() => {
    const fetchOrder = async () => {
      console.log('Fetching order data for uniqueLinkId:', uniqueLinkId);
      try {
        const response = await fetch(
          `https://mapi2.3sigmacrm.com/api/v1/order/get-by-unique-link?uniqueLinkId=${uniqueLinkId}`
        );
        
        if (!response.ok) {
          console.error('API response not OK:', response.status, response.statusText);
          throw new Error('Failed to fetch order');
        }
        
        const data = await response.json();
        console.log('Received order data:', data);
        
        if (data.status) {
          // Validate required data
          if (!data.data?.orderId) {
            console.error('Missing order ID in response');
            throw new Error('Invalid order data received');
          }
          
          setOrderData(data.data);
          
          // Check for product images
          const hasImages = data.data.products?.some(product => {
            const hasValidImages = product.images?.length > 0;
            console.log(`Product ${product.name} has images:`, hasValidImages);
            return hasValidImages;
          });
          
          console.log('Products have images:', hasImages);
        
        } else {
          console.error('API returned error status:', data.message);
          throw new Error(data.message || 'Failed to fetch order');
        }
      } catch (err) {
        console.error('Error fetching order:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (uniqueLinkId) {
      console.log('Starting order fetch for ID:', uniqueLinkId);
      fetchOrder();
    } else {
      console.error('No uniqueLinkId provided');
      setError('Invalid order link');
    }
  }, [uniqueLinkId]);

  const handleDownloadPDF = () => {
    console.log('Download PDF clicked');
    // Add PDF download logic
  };

  const handleShare = () => {
    console.log('Share clicked');
    // Add share logic
  };

  if (loading) {
    console.log('Rendering loading state');
    return (
      <div className="min-vh-100 d-flex align-items-center justify-content-center" key="loading-state">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  if (error) {
    console.log('Rendering error state:', error);
    return (
      <div className="min-vh-100 d-flex align-items-center justify-content-center">
        <div className="text-danger">{error}</div>
      </div>
    );
  }

  if (!orderData) {
    console.log('No order data found');
    return (
      <div className="min-vh-100 d-flex align-items-center justify-content-center">
        <div className="text-secondary">No order data found</div>
      </div>
    );
  }

  console.log('Rendering order view with data:', orderData);

  return (
    <>
      <GlobalStyle />
      <OrderContainer>
        <div className="container-fluid container-md">
          
          <OrderCard>
            {/* Header Section */}
            <CompanySection>
  <div className="row align-items-start">
    <div className="col-md-6 mb-3 mb-md-0">  {/* Reduced bottom margin */}
      <div className="d-flex gap-3">
        <CompanyLogoSection organization={orderData.organization} />
        <CompanyInfo>
          <h5 className="company-name">{orderData.organization?.name}</h5>
          <div className="company-details">
            <div className="mb-1">{orderData.organization?.address}</div>  {/* Reduced margin */}
            {orderData.organization?.phone && (
              <div className="contact-item">
                <Phone size={14} />
                <span>{orderData.organization.phone}</span>
              </div>
            )}
            {orderData.organization?.website && (
              <div className="contact-item">
                <Globe size={14} />
                <span>{orderData.organization.website}</span>
              </div>
            )}
          </div>
        </CompanyInfo>
                  </div>
                </div>
                <div className="col-md-6">
                  <OrderHeader>
                    <h2>INVOICE</h2>
                    <div className="order-meta">
  <div>
    <span>Order No:</span>
    <span>{orderData.orderId}</span>
  </div>
  <div>
    <span>Date:</span>
    <span>{new Date(orderData.createdAt).toLocaleDateString()}</span>
  </div>
  <div>
    <span>Prepared By:</span>
    <span>{orderData.createdBy}</span>
  </div>
</div>
                  </OrderHeader>
                </div>
              </div>
            </CompanySection>

            {/* Bill To Section */}
            <BillToSection>
              <h6>Bill To:</h6>
              <div className="row g-3">
                {orderData.lead?.name && (
                  <div className="col-md-6">
                    <div className="contact-info">
                      <FileText size={16} />
                      <span>{orderData.lead.name}</span>
                    </div>
                  </div>
                )}
                {orderData.lead?.phone && (
                  <div className="col-md-6">
                    <div className="contact-info">
                      <Phone size={16} />
                      <span>{orderData.lead.phone}</span>
                    </div>
                  </div>
                )}
                {orderData.lead?.email && (
                  <div className="col-md-6">
                    <div className="contact-info">
                      <Mail size={16} />
                      <span>{orderData.lead.email}</span>
                    </div>
                  </div>
                )}
                {orderData.lead?.addres?.address && (
                  <div className="col-md-6">
                    <div className="text-secondary small">
                      {orderData.lead.addres.address}
                    </div>
                  </div>
                )}
              </div>
            </BillToSection>

        {/* Products Section */}
<div className="p-4">
  {/* Desktop View */}
  <div className="d-none d-md-block">
    <ProductsTable>
      <thead>
        <tr>
          <th style={{width: "50px"}}>#</th>
          <th>Item</th>
          <th>Description</th>
          <th className="text-end">Price</th>
          <th className="text-end" style={{width: "80px"}}>Qty</th>
          <th className="text-end" style={{width: "120px"}}>Amount</th>
          <th style={{width: "100px"}}>Images</th>
        </tr>
      </thead>
      <tbody>
        {orderData.products?.map((product, index) => (
          <tr key={index}>
            <td className="text-secondary">{index + 1}</td>
            <td>{product.name}</td>
            <td className="text-secondary">{product.description}</td>
            <td className="text-end">INR {product.unitPrice}</td>
            <td className="text-end">{product.quantity}</td>
            <td className="text-end">INR {product.unitPrice * product.quantity}</td>
            <td>
  {product.images?.length > 0 && (
    <button 
      className="btn btn-link text-primary p-0 d-flex align-items-center gap-1"
      onClick={() => {
        console.log('Opening gallery for product index:', index);
        setSelectedProductIndex(index);
      }}
    >
      <span className="small">View {product.images.length}</span>
      <ChevronRight size={14} />
    </button>
  )}
</td>
          </tr>
        ))}
      </tbody>
    </ProductsTable>
  </div>

  {/* Mobile View */}
  <div className="d-md-none">
    {orderData.products?.map((product, index) => (
      <div key={index} className="card mb-3">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-start mb-2">
            <div>
              <div className="fw-medium fs-6">{product.name}</div>
              {product.description && (
                <div className="text-secondary small">{product.description}</div>
              )}
            </div>
            <div className="text-end">
              <div className="fw-medium">INR {product.unitPrice * product.quantity}</div>
              <div className="text-secondary small">
                INR {product.unitPrice} × {product.quantity}
              </div>
            </div>
          </div>
          
        
       
          {product.images?.length > 0 && (
  <div className="mt-3 pt-2 border-top">
    <button 
      className="btn btn-light btn-sm w-100 d-flex align-items-center justify-content-center gap-2"
      onClick={() => {
        console.log('Opening gallery for product index:', index);
        setSelectedProductIndex(index);
      }}
    >
      <FileImage size={16} />
      <span>View {product.images.length} product images</span>
      <ChevronRight size={16} />
    </button>
  </div>
)}
        </div>
      </div>
    ))}
  </div>

 
</div>
           {/* Summary Section */}
<SummarySection>
  <div className="row">
    {/* Notes Section - Left on Desktop, Bottom on Mobile */}
    <div className="col-md-7 order-md-1 order-2">
      {orderData.notes && (
        <div className="notes-section">
          <h6>Notes</h6>
          <div className="notes-content">
            {orderData.notes.split('\n').map((line, index) => (
              <p key={index}>{line}</p>
            ))}
          </div>
        </div>
      )}
    </div>

    {/* Summary calculations - Right on Desktop, Top on Mobile */}
    <div className="col-md-5 order-md-2 order-1">
      <div className="summary-row subtotal">
        <span>Subtotal</span>
        <span>₹{Number(orderData.subTotal).toLocaleString('en-IN')}</span>
      </div>

      {orderData.additionalCharges?.map((charge, index) => (
        <div key={index} className="summary-row charges">
          <span>{charge.title}</span>
          <span>₹{Number(charge.amount).toLocaleString('en-IN')}</span>
        </div>
      ))}

      {orderData.discount && (
        <div className="summary-row discount">
          <span>Discount ({orderData.discount.type === "fixed" ? "Fixed" : `${orderData.discount.rate}%`})</span>
          <span className="amount">-₹{Number(orderData.discount.amount).toLocaleString('en-IN')}</span>
        </div>
      )}

      {orderData.taxes?.map((tax, index) => (
        <div key={index} className="summary-row gst">
          <span>{tax.title.toUpperCase()} ({tax.rate}%)</span>
          <span>₹{Number(tax.amount).toLocaleString('en-IN')}</span>
        </div>
      ))}

      <div className="summary-row total">
        <span>Total Amount</span>
        <span>₹{Number(orderData.total).toLocaleString('en-IN')}</span>
      </div>

      {orderData.receivedAmount > 0 && (
        <>
          <div className="summary-row payment-details">
            <span>Amount Received ({orderData.receivedPaymentMode})</span>
            <span>₹{Number(orderData.receivedAmount).toLocaleString('en-IN')}</span>
          </div>
          <div className="summary-row payment-details">
            <span>Balance Due</span>
            <span>₹{(Number(orderData.total) - Number(orderData.receivedAmount)).toLocaleString('en-IN')}</span>
          </div>
        </>
      )}
    </div>
  </div>
</SummarySection>

            {/* Footer Section */}
            <FooterSection>
              <div className="row g-4">
              {orderData.bankDetails && 
                   Object.entries(orderData.bankDetails).every(([_, value]) => value) && (
                  <div className="col-md-6">
                    <h6>Bank Details</h6>
                    <div className="details">
                      <table className="w-100">
                        <tbody>
                          <tr>
                            <td className="py-1 text-secondary">Bank Name</td>
                            <td className="py-1 text-end">{orderData.bankDetails.bankName}</td>
                          </tr>
                          <tr>
                            <td className="py-1 text-secondary">Account Number</td>
                            <td className="py-1 text-end">{orderData.bankDetails.accountNumber}</td>
                          </tr>
                          <tr>
                            <td className="py-1 text-secondary">IFSC Code</td>
                            <td className="py-1 text-end">{orderData.bankDetails.ifscCode}</td>
                          </tr>
                          <tr>
                            <td className="py-1 text-secondary">Account Holder</td>
                            <td className="py-1 text-end">{orderData.bankDetails.accountHolderName}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}

                {orderData.termsAndCondition && (
                  <div className="col-md-6">
                    <h6>Terms and Conditions</h6>
                    <div className="details">
                      {orderData.termsAndCondition}
                    </div>
                  </div>
                )}
              </div>
              
            </FooterSection>
          </OrderCard>
        </div>
      </OrderContainer>
      {selectedProductIndex !== null && (
 <ProductGallery 
 products={orderData.products}
 onClose={() => setSelectedProductIndex(null)}
/>
)}
    </>
  );
};


export default OrderSharedView;